import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useAnimationFrame, useInScreen } from "Utils/Hooks"
import Button from "Components/Button"
import Rd from "./RD"


const StCont = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .row-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  
  & > * {
    flex-basis: 50%;
    padding: 15px;
  }

  .title {
    margin-bottom: 15px;
    display: block;
    font-size: 0.9em;
    line-height: normal;
    max-width: 80%;
  }

  .canvas-wrapper {
    width: 480px;
    height: 270px;

    canvas {
      border: 2px solid black;
    }
  }

  .graph-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .graph-wrapper {
    position: relative;
    color: white;
    font-size: 11px;

    &::before {
      content: '0';
      display: block;
      position: absolute;
      bottom: 6px;
      left: 6px;
    }

    &::after {
      content: '1';
      display: block;
      position: absolute;
      top: 0px;
      left: 6px;
    }
  }
`

const Example2 = () => {
  const [ratio, setRatio] = useState(0.1)
  const history = useRef(Array(12).fill(0))
  const avg = useRef(0)
  const [average, setAverage] = useState(0)
  const canvasRef = useRef()
  const rd = useRef()
  const wrapper = useRef()
  const inScreen = useInScreen(wrapper)
  const timer = useRef(0)
  const lastSample = useRef(-9999)

  const drawHistory = () => {
    const cvs = canvasRef.current
    const ctx = cvs.getContext('2d')
    const W = cvs.width
    const H = cvs.height
    const hist = history.current

    // clear
    ctx.fillStyle = 'blue'
    ctx.fillRect(0, 0, W, H)

    // draw lines
    ctx.strokeStyle = 'red'
    ctx.lineWidth = 3
    ctx.save()
    ctx.transform(1, 0, 0, -1, 0, H)
    ctx.translate(20, 10)
    ctx.beginPath()
    ctx.moveTo(0, hist[0] * (H-20))
    for (let i = 1; i < hist.length-1; i++) {
      const X = (i/hist.length) * (W-20)
      ctx.lineTo(X, hist[i] * (H-20))
    }
    ctx.stroke()

    // draw average
    ctx.lineWidth = 2
    ctx.strokeStyle = '#00ff00'
    ctx.beginPath()
    ctx.moveTo(0, avg.current * (H-20))
    ctx.lineTo(W-50, avg.current * (H-20))
    ctx.stroke()

    ctx.restore()
  }

  useEffect(() => {
    rd.current.init()
    drawHistory()

    return () => {
    }
  }, [])

  useAnimationFrame((dt) => {
    if (inScreen.current) {
      timer.current+= dt

      if (timer.current-lastSample.current > 100) {
        lastSample.current = timer.current
        const estBlack = rd.current.getEstimateBlackRatio()
        // draw the history to the canvas
        history.current.shift()
        history.current.push(estBlack)
        avg.current = history.current.reduce((a,b) => a+b) / history.current.length
        setAverage(avg.current)
        drawHistory()
      }
    }
  })

  const restart = () => {
    rd.current.restart()
    history.current = Array(12).fill(0)
    avg.current = 0
    setAverage(0)
  }

  const onChange = (event) => {
    setRatio(event.target.value)
    rd.current.stop()
    rd.current.init()
  }

  return (
    <StCont>

      <div className="row-row">
        <div className="col">
          <label>Initial ratio</label>
          <input 
            type="range" 
            min={0} 
            max={1} 
            step={0.001} 
            value={ratio} 
            onChange={onChange}
          />
        </div>
        <Button onClick={restart}>Restart transition</Button>
      </div>

      <div className="row">
        <div className="canvas-wrapper" ref={wrapper}>
          <Rd init={ratio} ref={rd} withAnalysis={true} />
        </div>
        <div className="graph-container">
          <div className="graph-wrapper">
            <canvas width="300" height="150" ref={canvasRef} />
          </div>
          <span
            style={{
              color: average > 0.95 ? `#00ff00` : 'black',
              fontWeight: average > 0.95 ? `bold` : 'normal',
            }}
          >
            average = {average.toFixed(3)}
          </span>
        </div>
      </div>

    </StCont>
  )
}

export default Example2