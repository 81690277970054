import React, { forwardRef } from "react"
import styled from "styled-components"


const Content = forwardRef((props, ref) => {
  return (
    <>
      <p>
        <strong>Your objective in this experiment is to reach the bottom of this document, <u>without using the scrollbar on the right</u>.</strong> The scrollbar was left for a better visualisation of the evolution of the scroll, but using it directly kinda breaks the experiment.
      </p>

      <p style={{ position: 'relative' }}>
        Perturbations of a natural user input will result in a discomfort of the usage. By nature, it will be unexpected because one almost never encounters such perturbations in their usual browsing of the internet. There’s a reason for that: this is a high risk - low reward solution to a probably non-existing problem. 
        <span className="anchor-target" ref={ref}></span>
      </p>

      <p>
        First of all, why would we want to perturb the way users are used to interact with documents on the web ? A web application should be built with regards to how the web is used if we want it to succeed. At the pace of today’s world, we shouldn’t expect a tool to be used by everyone if its most simple usage requires many hours of practice.
      </p>

      <p>
        Moreover, there are chances that even tiny perturbations may feel too unnatural for some users and there is a risk that only a bad memory of their experience on the website will remain. 
      </p>

      <p>
        Besides the risks, the profit of such design decisions should be explored as well. I don’t see many cases in which annoying the user will be profitable to the majority of the users. For instance, let’s examine newsletter pop-ins which appear as a user scrolls down a page. This would be an example of a perturbation to the scroll, from which only a viewport transformation is expected. By doing so, it catches his attention: there are high chances that he will take note of the pop-in, this is the reward. However, it will also probably upset the user. After all, for many of them, the pop-in will only be an undesired element which comes between him and the contents he desires. There are high chances that he will close the popin as soon as it opens: it becomes only an annoyance which makes the experience worse, this is the risk. 
      </p>

      <p>
        There are cases, however, in which the intent of a design would be to create a discomfort in the experience. This experiment, for instance, makes usage of such discomfort to create a controlled frustration (I hope). 
      </p>

      <p>
        With such frustration comes the need to make it stop. Some usual inputs do not result in the expected outcome. Some parts of this page seem accessible, but they also seem to resist you. However, if you persist, you will have the chance some others didn’t have: see the holy mysterious content you wanted so much.
      </p>
    </>
  )
})

export default Content