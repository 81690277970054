import React from "react"
import styled from "styled-components"



const Introduction = () => {
  return (
    <>
      <p>
        <em>First of all, please try to interact with this document as you would normally do with any other. </em>
      </p>
      <p>
        This experiment revolves around a key idea: trying to determine on which block the focus of the user lies. I took a very naïve approach in the implementation to derive this information. It was out of the scope of this project to build a sophisticated solution to solve this problem, but rather try to demonstrate how its usage could be made as a creative tool. In that matter, it is important for its appreciation to interact with it <strong>as it feels natural to you</strong>.
      </p>
      <p>
        It is necessary to emphasize the importance of <strong>natural</strong> inputs. 
      </p>
      <p data-animation="default-holes">
        As we get used to interacting with documents on the web, we create mechanisms to use our devices to navigate inside and between them. Because almost everybody uses the same tools, we have all built the same mechanisms. This is by no means the fruit of randomness. We have designed standards and tools so that the information could be delivered in a consistent way for anybody around the world (cf. intro). Because of the choices we have made over the years and the tools we have decided were best, we keep on evolving the technologies to deliver the best user experience at the end of the pipelines. There is more money to make through these tools than it has ever been possible, after all. So it makes sense that there has been an unification of the usage of our devices.
      </p>
      <p>
        Of course, we each have our own ways to interact with a document. Some of us do not have the privilege to see, so they only interact with documents with the sound. This project is aimed at the “general trend”, and tries to build upon it. But it will be interesting to explore where the limits of these concepts are for the people interacting with the internet in a way specific to their constraints. And more importantly, what can be done to reach them in these cases.
      </p>
      <p>
        To conclude, what I am describing as natural inputs is not only the mechanism one individual has developed, but rather the common human mechanisms we developed to interact with documents as our brains tried to adapt to how the web was, is and will be. So, maybe a good definition to a natural input would be:
      </p>
      <p data-animation="default-red">
        <strong>A Natural input</strong> is an unconscious behavior triggered in the pursuit of the information partially hidden by the nature of his device or the virtual document.
      </p>
    </>

  )
}

export default Introduction