import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Home from "Containers/Home"
import Introduction from "Containers/Introduction"
import Technical from "Containers/Technical/index"
import Collaborative from "Containers/Collaborative"

import ExperimentContainer from "Components/ExperimentContainer"
import { ExperimentsCollection, DefaultExperiment } from "Experiments/collection"


const Root = () => {
  return (
    <Router>
      <Switch>

        {ExperimentsCollection.map((Experiment, idx) => (
          <Route key={idx} path={Experiment.link}>
            <ExperimentContainer experiment={Experiment} />
          </Route>
        ))}

        <Route path="/introduction" component={Introduction} />
        <Route path="/technical-overview" component={Technical} />
        <Route path="/collaboration" component={Collaborative} />
        <Route path="/" component={Home} />

      </Switch>
    </Router>
  )
}

export default Root