/**
 * Each experiment should be placed in this collection. This collection is used as a source of data for the view so that
 * it knows every experiment that it needs to display.
 * The Collection is a simple array of pointers to the classes of the experiments. From this array only can be derived the
 * whole data that is required for the view to display the experiments.
 */

import MaterializingExperimentComponent from "Experiments/Home"
import ArtisticExpressionExperiment from "Experiments/ArtisticExpression"
import NaturalInputsExperiment from "Experiments/NaturalInputs"
import PerturbationsExperiment from "Experiments/Perturbations"
import SemanticExperiment from "Experiments/Semantic"

/**
 * @type {Array.<Experiment>}
 */
export const ExperimentsCollection = [
  MaterializingExperimentComponent, 
  ArtisticExpressionExperiment,
  NaturalInputsExperiment,
  PerturbationsExperiment,
  SemanticExperiment,
]

export const DefaultExperiment = MaterializingExperimentComponent

export default ExperimentsCollection