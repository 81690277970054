import React, { useMemo } from "react"
import styled from "styled-components"
import { ramp } from "./Graph"


const StInput = styled.input`
  appearance: none;
  width: 200px;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 14px;
    cursor: pointer;
    background: white;
    border: 1px solid rgba(0,0,0, 0.2);
    border-radius: 50px;
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(150,150,150,1) 43%, rgba(150,150,150,1) 57%, rgba(0,255,0,1) 100%);
    padding: 0 5px;
  }
  &::-webkit-slider-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: ${props => props.col};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
    transition: box-shadow .5s;
    border: 2px solid black;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);

    &:hover {
      box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.26);
    }

    &:active {
      box-shadow: 0px 0px 0px 1px rgba(0,0,0,1);
    }
  }
`

const StInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  font-weight: bold;
  text-align: center;
`

const Range = (props) => {
  const { value } = props

  const text = useMemo(() => {
    let t = "😢<br/>Very bad"
    if (value >= 0.85) {
      t = "😃<br/>Very good"
    }
    else if (value >= 0.67) {
      t = "🙂<br/>Good"
    }
    else if (value >= 0.33) {
      t = "😐<br/>Neutral"
    }
    else if (value >= 0.15) {
      t = "😟<br/>Bad"
    }
    return t
  }, [value])

  const color = useMemo(() => ramp.rgbAt(value).toRgbString(), [value])

  return (
    <aside>
      <StInfo dangerouslySetInnerHTML={{ __html: text }}/>
      <StInput type="range" {...props} col={color} />
    </aside>
  )
}


export default Range