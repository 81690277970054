import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import DomToEnv from "Utils/DomToEnv"
import { useAnimationFrame, useInScreen, useMouse } from "Utils/Hooks"
import FakeWebPage from "../FakeWebPage"
import Node from "Experiments/Perturbations/Physics/Node"
import { lerp } from "Utils/maths"


const StCont = styled.div`
  width: 100%;

  .title {
    margin-bottom: 15px;
    display: block;
    font-size: 0.9em;
    line-height: normal;
    max-width: 80%;
  }

  canvas {
    border: 2px solid black;
  }
`

const nbPoints = 12

const Example = () => {
  const container = useRef()
  const canvasRef = useRef()
  const nodes = useRef()

  useEffect(() => {
    const cvs = canvasRef.current
    cvs.width = container.current.clientWidth
    cvs.height = container.current.clientWidth * 9 / 16

    // find start and end coordinates
    const startX = cvs.width * .2
    const Y = cvs.height * .5
    const endX = cvs.width * .8

    // initialize the nodes
    nodes.current = []
    for (let i = 0; i < nbPoints; i++) {
      let X = lerp(startX, endX, i / nbPoints)
      nodes.current[i] = new Node(X, Y, i === 0 || i === nbPoints-1)
    }

    // and their links
    for (let i = 1; i < nbPoints-1; i++) {
      nodes.current[i].addEdge(nodes.current[i-1])
      nodes.current[i].addEdge(nodes.current[i+1])
    }
    nodes.current[0].addEdge(nodes.current[1])
    nodes.current[nbPoints-1].addEdge(nodes.current[nbPoints-2])

    return () => {
    }
  }, [])

  const inScreen = useInScreen(canvasRef)
  const mouse = useMouse(-100, -100)

  useAnimationFrame((dt, t) => {
    if (inScreen.current) {
      const cvs = canvasRef.current
      const ctx = cvs.getContext('2d')

      const ctBounds = cvs.getBoundingClientRect()

      const mouseX = mouse[0].current - ctBounds.x
      const mouseY = mouse[1].current - ctBounds.y
  
      ctx.fillStyle = "#000000"
      ctx.fillRect(0, 0, cvs.width, cvs.height)

      // update the acc from links
      for (const node of nodes.current) {
        node.updateLinksForces(0.05)
        node.updateMouse(mouseX, mouseY, 50, 12)
      }

      // updates pos from acc
      for (const node of nodes.current) {
        node.update(dt*0.005, false)
      }

      // draw mouse
      ctx.fillStyle = 'yellow'
      ctx.beginPath()
      ctx.arc(mouseX, mouseY, 50, 0, 2*Math.PI)
      ctx.fill()

      // draw the links
      ctx.strokeStyle = 'red'
      ctx.lineWidth = 2
      for (const node of nodes.current) {
        for (const edge of node.edges) {
          ctx.beginPath()
          ctx.moveTo(node.pos.x, node.pos.y)
          ctx.lineTo(edge.pos.x, edge.pos.y)
          ctx.stroke()
        }
      }

      // draw the nodes
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)'
      ctx.fillStyle = 'white'
      for (const node of nodes.current) {
        ctx.beginPath()
        ctx.arc(node.pos.x, node.pos.y, 10, 0, 2*Math.PI)
        ctx.stroke()
        ctx.beginPath()
        ctx.arc(node.pos.x, node.pos.y, node.fixed ? 10 : 3, 0, 2*Math.PI)
        ctx.fill()
      }

    }
  })

  return (
    <StCont ref={container}>

      <canvas ref={canvasRef} />
      <span className="title">Mouse collider is draw in yellow, fixed partcles are filled with white</span>

    </StCont>
  )
}

export default Example