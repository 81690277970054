import React from "react"
import styled from "styled-components"

import { WebGLRenderer, WebGLRenderTarget, Scene, OrthographicCamera, RawShaderMaterial, Mesh, PlaneBufferGeometry, 
  LinearFilter, RepeatWrapping, Vector2, Vector3 } from "three"
import quadVS from "Utils/GL/shaders/quad.vert.glsl"
import rdInitFS from "Utils/GL/shaders/rd-init-tuto.frag.glsl"
import rdFS from "Utils/GL/shaders/rd-simple.frag.glsl"
import textureFS from "Utils/GL/shaders/bw.frag.glsl"


class RD extends React.Component {
  mounted = true
  started = -99999
  duration = 30000

  constructor (props) {
    super(props)

    this.canvasRef = React.createRef() 
  }

  componentDidMount () {
    const cvs = this.canvasRef.current
    cvs.width = cvs.parentNode.clientWidth
    cvs.height = cvs.parentNode.clientHeight

    // setup three basic stuff
    this.renderer = new WebGLRenderer({
      canvas: cvs,
      preserveDrawingBuffer: this.props.withAnalysis || false,
    })
    this.renderer.setSize(cvs.width, cvs.height)
    this.scene = new Scene()
    this.camera = new OrthographicCamera(0, 1, 0, 1, 0, 100)

    // buffers for the RD 
    this.bufferRD1 = new WebGLRenderTarget(cvs.width, cvs.height, { 
      minFilter: LinearFilter, 
      magFilter: LinearFilter,
      wrapS: RepeatWrapping,
      wrapT: RepeatWrapping,
    })
    this.bufferRD2 = new WebGLRenderTarget(cvs.width, cvs.height, { 
      minFilter: LinearFilter, 
      magFilter: LinearFilter,
      wrapS: RepeatWrapping,
      wrapT: RepeatWrapping,
    })

    // the initialisation material
    this.initMat = new RawShaderMaterial({
      uniforms: {
        uStrength: { value: 0.5 },
      },
      vertexShader: quadVS,
      fragmentShader: rdInitFS
    })

    // the reaction diff material 
    this.rdMat = new RawShaderMaterial({
      uniforms: {
        _LastFrameTexture: { value: this.bufferRD2 },
        _DiffRateA: { value: 1 },
        _DiffRateB: { value: 0.7 },
        _FeedRate: { value: 0.02 },
        _KillRate: { value: 0.0 },
        _Resolution: { value: new Vector2(cvs.width, cvs.height) },
        _Time: { value: 0 },
      },
      vertexShader: quadVS,
      fragmentShader: rdFS,
    })

    // the render material
    this.renderMat = new RawShaderMaterial({
      uniforms: {
        uTexture: { value: this.bufferRD1 },
        uColor: { value: new Vector3(0,1,0) },
      },
      vertexShader: quadVS,
      fragmentShader: textureFS,
    })

    this.quad = new Mesh(new PlaneBufferGeometry(), this.renderMat)
    this.scene.add(this.quad)

    // if we are in analysis mode, create the canvas for analysis
    if (this.props.withAnalysis) {
      this.anaCanvas = document.createElement("canvas")
      this.anaCtx = this.anaCanvas.getContext("2d")
      this.anaCanvas.width = (cvs.width*.25) | 0
      this.anaCanvas.height = (cvs.height*.25) | 0
    }

    // initialize
    this.init()

    // start the main loop
    this.loop()
  }

  componentWillUnmount () {
    this.mounted = false
  }

  getEstimateBlackRatio () {
    this.anaCtx.drawImage(this.renderer.domElement, 0, 0, this.anaCanvas.width, this.anaCanvas.height)
    const data = this.anaCtx.getImageData(0, 0, this.anaCanvas.width, this.anaCanvas.height).data
    let avg = 0

    // rough estimate of the average color 
    for (let i = 0; i < 40; i++) {
      let idx = (Math.random() * this.anaCanvas.width * this.anaCanvas.height) | 0
      avg+= data[idx*4] + data[idx*4 + 1] + data[idx*4 + 1]
    }
    avg/= 3*40
    
    // we use an history to reduce the error margin
    return (255 - avg) / 255
  }

  init () {
    this.initMat.uniforms['uStrength'].value = this.props.init
    this.quad.material = this.initMat
    this.quad.material.needsUpdate = true
    
    // render the initialisation to buffer 1
    this.renderer.setRenderTarget(this.bufferRD1)
    this.renderer.render(this.scene, this.camera)
    
    // render to canvas
    this.renderToCanvas()
  }

  stop () {
    this.started = -99999
  }

  rdPass () {
    const tmp = this.bufferRD1
    this.bufferRD1 = this.bufferRD2
    this.bufferRD2 = tmp

    this.rdMat.uniforms['_LastFrameTexture'].value = this.bufferRD2
    this.quad.material = this.rdMat
    this.quad.material.needsUpdate = true

    // render to target
    this.renderer.setRenderTarget(this.bufferRD1)
    this.renderer.render(this.scene, this.camera)
  }

  renderToCanvas () {
    this.renderMat.uniforms['uTexture'].value = this.bufferRD1
    this.quad.material = this.renderMat
    this.quad.material.needsUpdate = true
    this.renderer.setRenderTarget(null)
    this.renderer.render(this.scene, this.camera)
  }

  start () {
    this.started = performance.now()
  }

  restart () {
    this.init()
    this.start()
  }

  loop = () => {
    if (this.mounted) {
      requestAnimationFrame(this.loop)

      const time = performance.now()
      const t = (time - this.started) / this.duration

      if (t <= 1) {
        this.rdPass()
        this.renderToCanvas()
      }
    }
  }

  render () {
    return (
      <canvas ref={this.canvasRef} />
    )
  }
}

export default RD