import React from "react"
import styled from "styled-components"
import Example from "./Example"
import Example2 from "./Example2"


const RDTransition = () => {
  return (
    <>
      <h2 id="rd-transition">4. The timing issue of the Reaction-Diffusion transition</h2>

      <p>
        I wanted the transition from the 2nd experiment to the summary to make usage of the visuals in the background. If the kill rate of a Reaction-Diffusion system is set to 0, the chemical agent will spread until it fills the whole environment. 
      </p>

      <p>
        However, whatever the initial state is, the spreading of the chemical agent will advance at the same speed. So, if there is a good distribution of black areas in the environment, the time it takes for the growth to fill out the space will be way smaller than if there were big blank areas. This example demonstrates the issue I’m talking about:
      </p>

      <div className="example">
        <Example />
      </div>

      <p>
        Because it would be suitable for the user that the summary starts appearing as soon as the screen is filled with black, I had to find a solution to determine the point in time when the space is filled with black. The first idea that came to my mind is to compute the ratio of black pixels over the total number of pixels. However, to compute this exact value, it would require going through all the pixels of the buffer one by one, which is very expensive. I opted for a probabilistic approach. By sampling only a small amount of random pixels at a regular interval, and averaging the ratio over time, a good estimation can be computed and it is more than enough to get a consistent solution to this problem.
      </p>

      <p>
        In the next example, an analysis module estimates the ratio of black pixels every 100ms and stores the value in an history (drawn in red). Then, the average of the history is computed (in green). If the average gets close to 1, we can say with <strong>enough certitude</strong> that the texture is filled with black. By the way, due to the nature of Reaction-Diffusion, it is possible that even if the chemical spreads completely its value never reaches 1. This is why a threshold needs to be defined. In this case, 0.95 seems to be a good value. We can show the next page <strong>as soon as the average reaches 0.95</strong>.
      </p>

      <div className="example">
        <Example2 />
      </div>

      <p>
        I wanted to give an insight about this issue because, in my opinion, <strong>coming up with creative solutions will often introduce weird issues</strong>. Even if it seems that those issues cannot be solved, we should always look for creative technical solutions.
      </p>
    </>
  )
}

export default RDTransition