import React, { forwardRef, useRef } from "react"
import { Redirect, Route, withRouter } from "react-router-dom"
import styled from "styled-components"

import Nav from "./Nav"
import Page from "./Page"
import PageIntro from "./Pages/Introduction"
import PageClassi from "./Pages/Classification"
import PageUtil from "./Pages/Utilization"
import PageStory from "./Pages/Story"


export const PagesData = [
  {
    path: '/introduction',
    nav: 'Natural inputs',
    title: "What are natural inputs in the context of DOM interactions ?",
    component: PageIntro,
  },
  {
    path: '/classification',
    nav: 'Classification',
    title: "The classification of Natural inputs",
    component: PageClassi,
  },
  {
    path: '/utilizing-classification',
    nav: 'Utilizing the classification',
    title: "Utilizing the classification",
    component: PageUtil,
  },
  {
    path: '/tell-a-story',
    nav: 'Tell a story',
    title: "Tell a story",
    component: PageStory,
  },
]

const StCont = styled.main`
  width: 600px;
`

const Pages = ({ history, location, match, onPageLoaded }) => {
  //const subpath = location.pathname.replace(match.path, '')
  const path = match.path
  const activePage = useRef()
  const waitingPath = useRef()
  const preventNav = useRef(false)

  const navigate = (path) => {
    if (!preventNav.current) {
      if (!waitingPath.current) {
        activePage.current.startTransitionOut()
      }
      waitingPath.current = path
    }
  }

  const onTransitionEnd = () => {
    history.push(waitingPath.current)
    waitingPath.current = null
  }

  const onTransitionInEnd = () => {
    preventNav.current = false
  }

  const onTransitionInStart = () => {
    onPageLoaded()
    preventNav.current = true
  }

  return (
    <>
      <Nav onNavigate={navigate} />

      <StCont>
        {PagesData.map((page, idx) => (
          <Route key={idx} path={`${path}${page.path}`}>
            <Page 
              title={page.title}
              component={page.component}
              onLoad={onTransitionInStart}
              ref={activePage}
              onTransitionEnd={onTransitionEnd}
              onTransitionInEnd={onTransitionInEnd}
            />
          </Route>
        ))}
        <Route path="/">
          <Redirect to={`${path}${PagesData[0].path}`} />
        </Route>
      </StCont>
    </>
  )
}

export default withRouter(Pages)