import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import { withRouter } from "react-router-dom"
import { useMounted, useAnimationFrame, useWindowResize } from "Utils/Hooks"

import NavItem from "Components/NavItem"

import { ExperimentsCollection } from "Experiments/collection"
import MouseTarget from "Experiments/Home/MouseTarget"
import { getOffset } from "Utils/DOM"


const StCont = styled.div`
  color: white;
  overflow: hidden;
  min-height: 100vh;
  position: relative;

  canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .line-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 75px;
    gap: 60px;

    & > *:first-child {
      width: 125px;
      text-align: right;
    }
  }

  .from-left, .from-right {
    opacity: 0;
    transition: all 0.7s ease-out;
  }

  .from-left {
    transform: translateX(-200px);
  }

  .from-right {
    transform: translateX(200px);
  }

  ${props => props.mounted && `
    .from-left, .from-right {
      opacity: 1;
      transform: translateX(0);
    }
  `}
`

const StHeader = styled.header`
  margin-top: 160px;
  position: relative;

  .line {
    width: 100%;
    height: 2px;
    background: white;
  }

  h1 {
    font-weight: normal;
    font-variation-settings: 'wght' 500;
    font-size: 48px;
    word-spacing: 30px;
  }

  .box {
    position: absolute;
    width: 200px;
    height: 110px;
    top: 50%;
    left: 180px;
    border: 2px solid white;
    transform: translateY(-50%);
  }

  .info-line {
    width: 150px;
    height: 1px;
    background: white;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    left: 304px;
    transform-origin: 0 50%;
    transform: rotate(-45deg);
  }

  .info-text {
    position: absolute;
    left: 417px;
    top: -105px;
    font-variation-settings: 'wght' 300;
    opacity: 0.3;
    font-size: 13px;
  }
`

const StAuthor = styled.aside`
  position: fixed;
  right: 150px;
  top: 169px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.66);
`

const StNav = styled.nav`
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  margin-bottom: 160px;

  a {
    color: white;
    text-decoration: none;
  }

  .line-item {
    align-items: flex-start;
    padding: 15px 0;
  }
`

const Home = withRouter(({
  history,
}) => {
  const [mounted, unmount] = useMounted()
  const container = useRef()
  const canvasRef = useRef()
  const mainItemRef = useRef()    // default pointed item
  const activeItemRef = useRef()  // hovered item
  const artRef = useRef()
  const opacity = useRef(0.01)
  const menuTarget = useRef(new MouseTarget(
    window.innerWidth*.5, 
    window.innerHeight*.5, 
    0.02 + Math.random() * 0.05, 
    0.1 + Math.random() * 0.5
  ))
  const artTarget = useRef(new MouseTarget(
    0, 
    0, 
    0.02 + Math.random() * 0.05, 
    0.1 + Math.random() * 0.4
  ))
  const artPos = useRef()
  const moveVariation = useRef(50 + Math.random() * 100)
  const moveVariationSpeed = useRef(0.02 + Math.random() * 0.04)

  useEffect(() => {
    activeItemRef.current = mainItemRef.current
    setTimeout(() => {
      const bounds = artRef.current.getBoundingClientRect()
      const pt = {
        x: bounds.left + 0.5 * bounds.width,
        y: getOffset(artRef.current).top + bounds.height,
      }
      artPos.current = pt
      artTarget.current.pos.x = pt.x
      artTarget.current.pos.y = pt.y
    }, 1000)
  }, [])

  // fits the canvas to its container
  const fitCanvas = () => {
    const cvs = canvasRef.current
    cvs.width = container.current.clientWidth
    cvs.height = container.current.clientHeight
  }

  const onMouseEnterItem = (event, item) => {
    activeItemRef.current = item
  }

  const onMouseLeaveItem = (event) => {
    activeItemRef.current = mainItemRef.current
  }

  useWindowResize(fitCanvas, true)

  // main animation loop
  useAnimationFrame((dt, time) => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      opacity.current = Math.min(0.3, opacity.current*1.01)
  
      // the target's target will be located where the active element is
      const elem = activeItemRef.current
      const bounds = elem.getBoundingClientRect()
      const pt = {
        x: bounds.left + bounds.width * .85 + Math.cos(time*moveVariationSpeed.current) * moveVariation.current,
        y: elem.offsetTop + bounds.height * .5 + Math.sin(time*moveVariationSpeed.current) * moveVariation.current,
      }
  
      // target follows the active point
      let target = menuTarget.current
      let lastPoint = { ...target.pos }
      target.follow(pt.x, pt.y)
      target.update(dt*0.001 * 4)
  
      // finally draw a line between old & new positions
      ctx.strokeStyle = `rgba(255, 255, 255, ${opacity.current})`
      ctx.beginPath()
      ctx.moveTo(lastPoint.x, lastPoint.y)
      ctx.lineTo(target.pos.x, target.pos.y)
      ctx.stroke()
  
      // underlying "art"
      if (artPos.current) {
        target = artTarget.current
        lastPoint = { ...target.pos }
        target.follow(artPos.current.x + Math.cos(time*0.001) * 50, artPos.current.y + Math.sin(time*0.00015) * 3)
        target.update(dt*0.001 * 4)
        ctx.strokeStyle = `rgba(255, 255, 255, ${opacity.current*.5})`
        ctx.beginPath()
        ctx.moveTo(lastPoint.x, lastPoint.y)
        ctx.lineTo(target.pos.x, target.pos.y)
        ctx.stroke()
      }
    }
  })

  const navigateTo = (link) => {
    unmount(700).then(() => {
      history.push(link)
    })
  }

  return (
    <StCont mounted={mounted} ref={container}>

      <StHeader className="from-left" ref={mainItemRef}>
        <div className="info-line"></div>
        <span className="info-text">Document Object Model</span>
        <div className="line-item">
          <div className="line"></div>
          <h1>DOM <span ref={artRef}>art</span></h1>
        </div>
        <div className="box"></div>
      </StHeader>

      <StAuthor className="author from-right">
        presented by <a href="https://ciphrd.com" target="_blank">ciphrd</a>
      </StAuthor>

      <StNav>
        <NavItem
          link="/introduction"
          title="Introduction"
          onClick={() => navigateTo("/introduction")}
          description="An overview of what DOM art is"
          className="from-right"
          onMouseEnter={onMouseEnterItem}
          onMouseLeave={onMouseLeaveItem}
        />

        {ExperimentsCollection.map((experiment, idx) => (
          <NavItem
            key={idx}
            link={experiment.link}
            index={idx+1}
            title={experiment.title}
            onClick={() => navigateTo(experiment.link)}
            description={experiment.description}
            className="from-right"
            onMouseEnter={onMouseEnterItem}
            onMouseLeave={onMouseLeaveItem}
          />
        ))}

        <NavItem
          link="/technical-overview"
          title="A technical overview"
          onClick={() => navigateTo("/technical-overview")}
          description="Technical insights about how these experiments were made"
          className="from-right"
          onMouseEnter={onMouseEnterItem}
          onMouseLeave={onMouseLeaveItem}
        />

        <NavItem
          link="/collaboration"
          title="A collaborative project"
          onClick={() => navigateTo("/collaboration")}
          description="Open-source project"
          className="from-right"
          onMouseEnter={onMouseEnterItem}
          onMouseLeave={onMouseLeaveItem}
        />
      </StNav>

      <canvas ref={canvasRef} className="from-right" />

    </StCont>
  )
})

export default Home