import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useAnimationFrame } from "Utils/Hooks"
import Button from "Components/Button"
import Rd from "./RD"


const StCont = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  & > * {
    flex-basis: 50%;
    padding: 15px;
  }

  .title {
    margin-bottom: 15px;
    display: block;
    font-size: 0.9em;
    line-height: normal;
    max-width: 80%;
  }

  .canvas-wrapper {
    width: 480px;
    height: 270px;

    canvas {
      border: 2px solid black;
    }
  }
`

const Example = () => {
  const rd1 = useRef()
  const rd2 = useRef()

  useEffect(() => {
    rd1.current.init()
    rd2.current.init()

    return () => {
    }
  }, [])

  const restart = () => {
    rd1.current.restart()
    rd2.current.restart()
  }

  return (
    <StCont>

      <div className="row">
        <Button onClick={restart}>Restart transition</Button>
      </div>

      <div className="row">
        <div className="canvas-wrapper">
          <Rd init={0.1} ref={rd1} />
        </div>
        <div className="canvas-wrapper">
          <Rd init={0.4} ref={rd2} />
        </div>
      </div>

    </StCont>
  )
}

export default Example