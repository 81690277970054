import React from "react"
import styled from "styled-components"
import { Vector2 } from "three"


const friction = 0.05
const maxspeed = 3000
const gravity = 10
let id = 0

class Node {
  /**
   * @type {Array.<Node>}
   */
  edges = []

  constructor (x, y, fixed = false) {
    this.fixed = fixed
    this.id = id++

    this.pos = new Vector2(x, y)
    this.vel = new Vector2(0, 0)
    this.acc = new Vector2(0, 0)
  }

  addEdge (node) {
    this.edges.push(node)
  }

  removeEdge (node) {
    for (let i = 0; i < this.edges.length-1; i++) {
      this.edges.splice(i, 1)
      return true
    }
    return false
  }

  updateMouse = (x, y, rad, strength = 100) => {
    let dir = new Vector2(this.pos.x-x, this.pos.y-y)
    let d = dir.length()
    dir.normalize()
    if (d <= rad + 6) {
      this.acc.add(dir.multiplyScalar((rad + 6 - d) * strength))
    }
  }

  /**
   * 
   * @param {Array.<Node>} nodes 
   */
  updateRepulsion = (nodes, strength, rangeSq) => {
    let dir, d
    for (const n of nodes) {
      if (n === this) continue

      dir = this.pos.clone().sub(n.pos)
      d = dir.lengthSq()
      dir.normalize()

      if (d < rangeSq && d > 0) {
        this.acc.add(dir.multiplyScalar(strength / d))
      }
    }
  }

  updateLinksForces (springStr, growing = false) {
    const m = growing ? 0.05 : 1
    let dir, d
    for (const n of this.edges) {
      dir = n.pos.clone().sub(this.pos)
      d = dir.length()
      dir.normalize()
      this.acc.set(
        this.acc.x + dir.x * d * d * springStr * m,
        this.acc.y + dir.y * d * d * springStr * m,
      )
    }
  }

  update (dt, growing = false) {
    if (!this.fixed) {
      // gravity
      this.acc.y+= growing ? 0 : gravity
      this.vel.add(this.acc)
      let s = this.vel.length()
      const ms = growing ? 300 : maxspeed
      if (s > ms) {
        this.vel = this.vel.normalize().multiplyScalar(ms)
      }
      this.pos.add(this.vel.clone().multiplyScalar(dt))
      this.vel.multiplyScalar(1 - friction)
    }
    this.acc.set(0, 0)
  }
}

export default Node