import React, { forwardRef } from "react"
import styled from "styled-components"
import { useMounted } from "Utils/Hooks"


const StCont = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 120vw;
  height: 100vh;
  z-index: 100000;
  pointer-events: ${props => props.mounted ? 'none' : 'all'};
  transition: all 1s ease-in;
  transform-origin: 0% 100%;
  background: black;

  ${props => props.mounted && `
    transform: rotate(-10deg);
    bottom: 100%;
  `}
`

const Transitioner = forwardRef(({  }, ref) => {
  const [mounted, unmount] = useMounted()
  ref.current = unmount

  return (
    <StCont mounted={mounted} />
  )
})

export default Transitioner