import React from "react"
import styled from "styled-components"



const Utilization = () => {
  return (
    <>
      <p data-animation="default-holes">
        By listing all natural inputs on a page, and by classifying them, it is possible to create a map between the inputs and the background visual effects. Such a map will create some consistency between the navigation of the user and the visual feedback he will experience.
      </p>

      <p>
        For instance, this is how this experiment was built based on some selected inputs:
      </p>

      <ul>
        <li>
          combining scrolling and the position of the cursor (auto-guidance): allows for the detection of the block on which the focus of the user is
        </li>
        <li>
          clicking on a link / expanding expandable blocks: they both result in a content transformation, so the same effect will be used to emphasize this type of interaction
        </li>
        <li>
          changing the text of any input: content manipulation, will also result in the same effect being displayed
        </li>
      </ul>

      <p data-animation="intense">
        Once in a while, special blocks will trigger special effects and break the continuity of the whole. It can become extremely powerful to absorb the attention of the user when it is necessary. 
      </p>

      <p>
        It is <strong>not mandatory</strong> to rely on the classification of the natural inputs only, however it helps to keep some consistency in the whole experience. It’s easy to get lost in crazy-looking effects. It’s harder to only keep what’s essential to the well construction of a user experience, as it is in any design thinking.
      </p>
    </>
  )
}

export default Utilization