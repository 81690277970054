import Experiment from "Core/Experiment"

import { Engine, Render, Runner, Body, Composites, MouseConstraint, Mouse, World, Bodies, Vector } from 'matter-js' 
import { makeNoise2D } from "open-simplex-noise" 

import MaterializingExperimentComponent from "./Component"
import MouseTarget from "./MouseTarget"


// simplex noise instance
const snoise = makeNoise2D(Date.now())

class MaterializingExperiment extends Experiment {
  rectangles = []
  boundRectangles = []
  startViewSize = null
  isRunning = false
  mouseX = window.innerWidth * .5
  mouseY = window.innerHeight * .5
  showTargets = false

  constructor () {
    super()
    this.loop = this.loop.bind(this)
  }

  /**
   * 
   * @param {HTMLElement} element 
   * @param {Array.<HTMLElement>} interactiveElements
   */
  init (element, interactiveElements) {
    super.init(element)

    return new Promise((resolve) => {
      this.notifyOnLoadCallbacks(0.1)

      this.startViewSize = Vector.create(element.clientWidth, element.clientHeight)

      // create engine
      this.engine = Engine.create()
      this.world = this.engine.world
    
      // world modiications
      this.world.gravity.y = 0
    
      // create renderer
      this.render = Render.create({
        element: element,
        engine: this.engine,
        options: {
          width: element.clientWidth,
          height: element.clientHeight,
          background: '#000000',
        },
      })
      this.ctx = this.render.context 
    
      this.target1 = new MouseTarget(element.clientWidth*.5 - 60, element.clientHeight*.5 - 10) 
      this.target2 = new MouseTarget(element.clientWidth*.5 + 10, element.clientHeight*.5 + 110)
      this.target3 = new MouseTarget(element.clientWidth*.5 + 50, element.clientHeight*.5 - 80)

      this.notifyOnLoadCallbacks(0.3)
    
      // the bodies
      const group = Body.nextGroup(true)
      const particleOptions = {
        friction: 0.00001,
        collisionFilter: { 
          group: group,
        },
        render: { 
          visible: true,
        },
      }
      const constraintOptions = { 
        stiffness: 0.56,
        render: {
          lineWidth: 1.5
        }
      }
      const constraintOptions2 = { 
        stiffness: 0.9,
        render: {
          lineWidth: 1.5
        }
      }
      const constraintOptions3 = { 
        stiffness: 0.1,
        render: {
          lineWidth: 1.5
        }
      }
      this.cloth = Composites.softBody(element.clientWidth*.75, element.clientHeight*.5, 10, 10, 8, 5, false, 9, particleOptions, constraintOptions)
      const grp2 = Body.nextGroup(true)
      const particleOptions2 = {
        friction: 0.00001,
        collisionFilter: { 
          group: grp2,
        },
        render: { 
          visible: true,
        },
      }
      this.cloth2 = Composites.softBody(element.clientWidth*.25, element.clientHeight*.75, 6, 5, 10, 10, false, 9, particleOptions2, constraintOptions2)
      const grp3 = Body.nextGroup(true)
      const particleOptions3 = {
        friction: 0.00001,
        collisionFilter: { 
          group: grp3,
        },
        render: { 
          visible: true,
        },
      }
      this.cloth3 = Composites.softBody(element.clientWidth*.6, element.clientHeight*.75, 8, 6, 10, 10, false, 9, particleOptions3, constraintOptions3)

      this.notifyOnLoadCallbacks(0.5)
    
      // init as many rectangles as there are interactive DOM elements
      const rectangles = [] 
      const group2 = Body.nextGroup(true)
      for (const elem of interactiveElements) {
        const DOMrect = elem.getBoundingClientRect() 
        const body = Bodies.rectangle(0, 0, DOMrect.width, DOMrect.height, {
          isStatic: false,
          collisionFilter: {
            group: group2,
          },
          render: {
            visible: false 
          },
        }) 
        Body.setDensity(body, 10000) 
        this.rectangles.push({
          elem: elem,
          body: body,
        }) 
      }
      this.updateRectanglesPos()
      this.notifyOnLoadCallbacks(0.7)
    
      const width = 1000
      const group3 = Body.nextGroup(true)
      this.boundRectangles = [0,0,0,0].map(() => 
        Bodies.rectangle(400, 400, 400, 400, { 
          isStatic: true,
          collisionFilter: {
            group: group3,
          },
          render: {
            visible: false,
          },
        })
      )
      this.updateBounds()
      World.add(this.world, [
        this.cloth,
        this.cloth2,
        this.cloth3,
        ...this.boundRectangles,
        ...this.rectangles.map(r => r.body),
      ]) 
    
      // setup listeners
      document.addEventListener("mousemove", this.onMouseMoveDocument)
      document.addEventListener("mousedown", this.onMouseDownDocument)
      window.addEventListener("resize", this.onWindowResize)

      this.notifyOnLoadCallbacks(1)

      resolve()
    })
  }

  onMouseMoveDocument = (event) => {
    this.mouseX = event.clientX 
    this.mouseY = event.clientY
  }

  onMouseDownDocument = () => {
    const pos = Vector.create(this.mouseX, this.mouseY)
    this.clothRepel(this.cloth, pos, 20)
    this.clothRepel(this.cloth2, pos, 20)
    this.clothRepel(this.cloth3, pos, 20)
  }

  onWindowResize = () => {
    this.updateBounds()
  }

  start () {
    super.start()
    this.isRunning = true
    Render.run(this.render)
    this.loop()
  }

  stop () {
    super.stop()
    this.isRunning = false
    Render.stop(this.render)
  }

  loop () {
    if (this.isRunning) {
      requestAnimationFrame(this.loop)
      super.loop()  // compute the timers
  
      // ensure rectangles match with DOM
      this.updateRectanglesPos()
  
      this.target1.follow(this.mouseX, this.mouseY) 
      this.target1.update(this.dt * 0.001) 
      this.target2.follow(this.mouseX, this.mouseY) 
      this.target2.update(this.dt * 0.001) 
      this.target3.follow(this.mouseX, this.mouseY) 
      this.target3.update(this.dt * 0.001) 
  
      const n1 = snoise(0, this.elapsed*.02) * .5 + .5
      const follow1 = [0, 50]
      if (n1 > 0.5) {
        follow1.push(63);
      }
  
      this.clothFollow(this.cloth, this.target1.pos, follow1, 0.001 + n1 * 0.005) 
      this.clothFollow(this.cloth2, this.target2.pos, [2, 9], 0.001) 
      this.clothFollow(this.cloth3, this.target3.pos, [4, 28, 17, 16], 0.001) 
      
      if (this.showTargets) {
        // the lines
        this.ctx.strokeStyle = "#ff0000" 
        this.ctx.setLineDash([2, 4])
        this.ctx.beginPath()
        this.ctx.moveTo(this.target1.pos.x, this.target1.pos.y)
        this.ctx.lineTo(this.target2.pos.x, this.target2.pos.y)
        this.ctx.lineTo(this.target3.pos.x, this.target3.pos.y)
        this.ctx.lineTo(this.target1.pos.x, this.target1.pos.y)
        this.ctx.stroke()
        // the dots
        this.ctx.fillStyle = "#ff0000" 
        this.ctx.beginPath()
        this.ctx.arc(this.target1.pos.x, this.target1.pos.y, 3, 0, 2 * Math.PI)
        this.ctx.fill()
        this.ctx.beginPath()
        this.ctx.arc(this.target2.pos.x, this.target2.pos.y, 3, 0, 2 * Math.PI)
        this.ctx.fill()
        this.ctx.beginPath()
        this.ctx.arc(this.target3.pos.x, this.target3.pos.y, 3, 0, 2 * Math.PI)
        this.ctx.fill() 
        // reset
        this.ctx.setLineDash([]);
      }
  
      Engine.update(this.engine, Math.min(100, this.dt))
    }
  }

  /**
   * Synchronizes the rectangles on the 4 borders with the container size
   */
  updateBounds = () => {
    const depth = 256
    const padding = 256
    const rect = this.container.getBoundingClientRect()
    // "security" to prevent particles from leaking out of bounds
    rect.width+= padding
    rect.height+= padding

    // top
    Body.setVertices(this.boundRectangles[0], [
      Vector.create(0, 0),
      Vector.create(rect.width, 0),
      Vector.create(rect.width, depth),
      Vector.create(0, depth),
    ])
    Body.setPosition(this.boundRectangles[0], Vector.create((rect.width-padding)*.5, -depth*.5))

    // right
    Body.setVertices(this.boundRectangles[1], [
      Vector.create(0, 0),
      Vector.create(depth, 0),
      Vector.create(depth, rect.height),
      Vector.create(0, rect.height),
    ])
    Body.setPosition(this.boundRectangles[1], Vector.create(rect.width-padding+depth*.5, (rect.height-padding)*.5))

    // bottom
    Body.setVertices(this.boundRectangles[2], [
      Vector.create(0, 0),
      Vector.create(rect.width, 0),
      Vector.create(rect.width, depth),
      Vector.create(0, depth),
    ])
    Body.setPosition(this.boundRectangles[2], Vector.create((rect.width-padding)*.5, rect.height-padding+depth*.5))

    // left
    Body.setVertices(this.boundRectangles[3], [
      Vector.create(0, 0),
      Vector.create(depth, 0),
      Vector.create(depth, rect.height),
      Vector.create(0, rect.height),
    ])
    Body.setPosition(this.boundRectangles[3], Vector.create(-depth*.5, (rect.height-padding)*.5))

    // resize render to fit container
    this.render.canvas.width = this.container.clientWidth
    this.render.canvas.height = this.container.clientHeight
    this.render.options.width = this.container.clientWidth
    this.render.options.height = this.container.clientHeight
    Render.setPixelRatio(this.render, 'auto')
    Render.lookAt(this.render, {
      min: { x: 0, y: 0 },
      max: { x: this.container.clientWidth, y: this.container.clientHeight },
    })
  }

  /**
   * Synchronizes the rectangle bodies so that their shape matches their DOM elements counterparts
   */
  updateRectanglesPos () {
    for (const rect of this.rectangles) {
      const DOMrect = rect.elem.getBoundingClientRect() 
      
      rect.body.force.x = 0 
      rect.body.force.y = 0 
      Body.setAngularVelocity(rect.body, 0) 
      Body.setAngle(rect.body, 0) 
  
      Body.setVertices(rect.body, [
        Vector.create(0, 0),
        Vector.create(DOMrect.width, 0),
        Vector.create(DOMrect.width, DOMrect.height),
        Vector.create(0, DOMrect.height),
      ]) 
  
      Body.setPosition(
        rect.body, 
        Vector.create(
          rect.body.position.x = DOMrect.left + DOMrect.width * .5,
          rect.body.position.y = DOMrect.top + DOMrect.height * .5
        )
      ) 
    }
  }

  /**
   * Apply an attraction force to all the points of the cloth at the [indices] towards a target
   * @param {Body} cloth the cloth which has to follow the target
   * @param {{x: number, y: number}} target the 2D point to follow
   * @param {Array.<number>} indices an array of the indices of the points which should follow the target
   * @param {number} strength the strength of the attraction force towards the target
   */
  clothFollow (cloth, target, indices, strength) {
    let dir 
    for (const id of indices) {
      dir = Vector.create(target.x - cloth.bodies[id].position.x, target.y - cloth.bodies[id].position.y) 
      dir = Vector.normalise(dir) 
      cloth.bodies[id].force.x+= strength * dir.x 
      cloth.bodies[id].force.y+= strength * dir.y 
    }
  }

  /**
   * Apply a repulsion force to all the points of the cloth away from a target
   * @param {Body} cloth the cloth which has to follow the target
   * @param {{x: number, y: number}} target the 2D point to follow
   * @param {number} strength the strength of the attraction force towards the target
   */
  clothRepel (cloth, target, strength) {
    let dir, d2, mv, s
    for (const pt of cloth.bodies) {
      dir = Vector.sub(pt.position, target)
      d2 = Vector.magnitudeSquared(dir)
      dir = Vector.normalise(dir)
      s = Math.min(1.0, strength / d2)
      pt.force.x+= dir.x * s
      pt.force.y+= dir.y * s
    }
  }

  destroy () {
    return new Promise(resolve => {
      document.removeEventListener("mousemove", this.onMouseMoveDocument)
      document.removeEventListener("mousedown", this.onMouseDownDocument)
      window.removeEventListener("resize", this.onWindowResize)

      World.clear(this.world, false, true)  // clears the world recursively
      Engine.clear(this.engine)

      resolve()
    })
  }
}

MaterializingExperiment.link = "/materializing"
MaterializingExperiment.title = "Materializing DOM entities"
MaterializingExperiment.description = "Giving HTML nodes a physical presence"
MaterializingExperiment.component = MaterializingExperimentComponent

export default MaterializingExperiment