import React from "react";
import styled from "styled-components"
import ExperimentComponent from "Core/ExperimentComponent"
import NaturalInputsExperiment from "./index"
import { Link } from "react-router-dom";
// import Data from "./Data"

import MountingEffect from "./MountingEffect"
import Pages from "./Pages.js"


const StCont = styled.div`
  color: white;
  font-size: 15px;
  position: relative;
  transform-origin: center center;
  transition: all 1.5s ease-in;

  &.fade-out {
    transform: rotate(8deg) scale(0.8);
    opacity: 0;
  }

  &::after {
    content: "";
    display: block;
    pointer-events: none;
    position: fixed;
    top: 30%;
    bottom: 30%;
    width: 100%;
    background: rgba(255, 0, 0, 0.5);
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    opacity: 0;
  }

  & > canvas {
    position: fixed;
    z-index: -10;
  }

  header {
    position: absolute;
    top: 75px;
    left: 75px;
    z-index: 100;

    a {
      color: #00ff00;
      text-decoration: none;
      font-variation-settings: 'wght' 300;
      transition: all .3s;

      &:hover {
        font-variation-settings: 'wght' 900;
      }
    }
  }

  nav {
    position: fixed;
    z-index: 100;
    left: 75px;
    top: 250px;
    bottom: 0;
    width: 350px;
  }

  main {
    position: absolute;
    z-index: 100;
    left: 425px;
    top: 250px;
    min-height: calc(100vh - 250px);
    border-left: 1px solid white;
  }

  .active {
    //background: grey;
  }
`

class NaturalInputsExperimentComponent extends ExperimentComponent {
  constructor (props) {
    super(props)

    this.container = React.createRef()
    this.canvas = React.createRef()
    this.mountingRef = React.createRef()
  }

  init (element) {
    return this.experiment.init(this.container.current, this.canvas.current)
  }

  destroy () {
    return this.experiment.destroy()
  }

  componentDidMount () {
  }

  onPageLoaded = () => {
    window.scrollTo(0, 0)
    this.experiment.autoUpdateActiveElement()
  }

  startTransitionOut = (event) => {
    event.preventDefault()
    document.body.style.overflow = "hidden"
    const cont = this.container.current
    cont.ontransitionend = (evt) => {
      if (evt.path[0] === cont) {
        document.body.style.overflow = "visible"
        this.props.onStop()
      }
    }
    cont.classList.add('fade-out')
  }

  render () {
    return (
      <StCont ref={this.container}>
        <canvas ref={this.canvas} />
        <header>
          <h1><small>3.</small> {NaturalInputsExperiment.title}</h1>
          <Link to="/" onClick={this.startTransitionOut}>summary</Link>
          <MountingEffect ref={this.mountingRef} />
        </header>

        <Pages 
          onPageLoaded={this.onPageLoaded}
        />
      </StCont>
    )
  }
}

export default NaturalInputsExperimentComponent