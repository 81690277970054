import React from "react"
import styled from "styled-components"
import Example from "./Example"
import Example2 from "./Example2"
import Example3 from "./Example3"


const PhysicsScroll = () => {
  return (
    <>
      <h2 id="physics-scroll">3. Hacking the scroll with a physics simulation</h2>

      <p>
        In the 4th experiment, the scroll of the page is not only controlled by the user, but is also tied to a rope which is physically simulated. Achieving such an effect can be pretty tricky because it happens that physics simulations in real-time do approximations which can result in numerical instability, which can have a bad effect on the scroll. It’s a lot of trial and error to get the right values for the simulation. I wrote my own simulation for this experiment because I wanted full control over it. Moreover, I wanted to introduce differential growth once the rope breaks, which again requires a fine tuning on how the particles interact together. If you are interested in differential growth, I suggest reading <a href="https://jasonwebb.io/2019/05/differential-growth-experiments-in-javascript/" target="_blank">this article</a> by Jason Webb.
      </p>

      <p>
        The rope was modelled with a series of particles, linked together by spring forces. 2 particles are marked as fixed: the acceleration forces applied on them won’t change their position. Gravity is applied to all the nodes, pulling them down with less strength than their links. The following example is the very basis of the rope:
      </p>

      <div className="example">
        <Example />
      </div>

      <p>
        By fixing the position of a the right particle to a DOM element, we see how the elastic rope gets stretched depending on the distance, and the effect it has on the physics of the rope. Try scrolling in this fake page to see it in action:
      </p>

      <div className="example">
        <Example2 />
      </div>

      <p>
        Because of how the system was designed, it is possible to get the acceleration of any particle. Its Y component can then be used to manipulate the scroll, depending on its sign and its norm. By applying this mechanic, it is possible to naturally achieve the same effect than the one in experiment 4. In this last example, the Y component of the acceleration on the right-most particle is drawn as a green vector. The same value influences the scroll of the page. You can see the impact in this last example:
      </p>

      <div className="example">
        <Example3 />
      </div>

      <p>
        It should be noted that to prevent numeric instability from destroying the scroll, some smoothening is used on the force which is applied to it. This is a simple yet effective approach.
      </p>
    </>
  )
}

export default PhysicsScroll