import React from "react";
import styled from "styled-components"
import ExperimentComponent from "Core/ExperimentComponent"
import SemanticExperiment from "./index"
import Introduction from "./Introduction"
import NotedText from "./NotedText"
import { Link } from "react-router-dom"
import Range from "./Range"
import Transitioner from "./Transitioner"


const StCont = styled.div`
  min-height: 100vh;

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
  }

  header {
    position: absolute;
    top: 75px;
    left: 75px;
  }

  aside {
    position: fixed;
    right: calc(50% + 380px);
    top: 380px;
    z-index: 20;
  }

  main {
    width: 100%;
    position: absolute;
    top: 300px;
    z-index: 10;
    padding-bottom: 300px;
    background: rgba(255, 255, 255, 0.2);

    .content {
      width: 600px;
      margin: 0 auto;
    }

    .intro {
      p {
        margin-top: 50px;
      }
    }

    hr {
      border: none;
      background: black;
      height: 1px;
      width: 50%;
      margin: 50px auto 0;
    }

    article {
      h4 {
        margin-top: 70px;
      }
      p {
        margin-top: 50px;
      }
    }
  }
`

class SemanticExperimentComponent extends ExperimentComponent {
  state = {
    target: 0.5,
  }

  constructor (props) {
    super(props)

    this.textRef = React.createRef()
    this.canvasRef = React.createRef()
    this.transitionerRef = React.createRef()
  }

  get canvas () {
    return this.canvasRef.current
  }

  init (element) {
    window.addEventListener('resize', this.autoFitCanvas)
    this.autoFitCanvas()
    return this.experiment.init(element, this.textRef.current, this.canvas)
  }
  
  destroy () {
    window.removeEventListener('resize', this.autoFitCanvas)
    return this.experiment.destroy()
  }

  autoFitCanvas = () => {
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
  }

  componentDidMount () {
  }

  startTransitionOut = (event) => {
    event.preventDefault()
    const trans = this.transitionerRef.current
    trans(1100).then(() => this.props.onStop())
  }

  onTargetChange = (event) => {
    this.setState(st => ({
      ...st,
      target: event.target.value,
    }))
    this.experiment.targetNote = event.target.value
  }

  render () {
    return (
      <StCont>

        <canvas ref={this.canvasRef} />

        <header>
          <h1>{ SemanticExperiment.title }</h1>
          <Link to="/" onClick={this.startTransitionOut}>summary</Link>
        </header>

        <Range 
          value={this.state.target}
          min="0"
          max="1"
          step="0.0001"
          onChange={this.onTargetChange} 
        />

        <main>
          <div className="content">
            <section className="intro">
              <Introduction />
            </section>
            <hr />
            <article ref={this.textRef}>
              <NotedText />
            </article>
          </div>
        </main>

        <Transitioner ref={this.transitionerRef} />

      </StCont>
    )
  }
}

export default SemanticExperimentComponent