import React from "react"
import styled from "styled-components"
import { makeNoise2D } from "open-simplex-noise" 

// simplex noise instance
const snoise = makeNoise2D(Date.now())

const StCont = styled.div`
  position: absolute;
  top: 0; 
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: ${props => props.mounted ? 'none' : 'black'};
  pointer-events: ${props => props.mounted ? 'none' : 'all'};

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const duration = 800

/**
 * This class allows any component which renders it to propose an utility for showing / hiding a component when felt necessary.
 * By default, it hides any content under it, and only whow it when the method is called from the parent. A method can be called 
 * to hide and once complete, it calls the onHide prop method.
 */
class MountingEffectComponent extends React.Component {
  state = {
    mounted: false,
  }

  // the direction of the animation
  direction = 1
  startTimer = 0
  firstFrame = false

  // if set to true the main loop will auto cancel
  unmounted = false

  constructor (props) {
    super(props)

    this.container = React.createRef()
    this.canvasRef = React.createRef()
  }

  get canvas () {
    return this.canvasRef.current
  }

  componentDidMount () {
    this.resizeObserver = new ResizeObserver(entries => {
      this.autoFitCanvas()
    })
    this.resizeObserver.observe(this.container.current)
    this.autoFitCanvas()
    this.ctx = this.canvas.getContext('2d')
    this.startTransitionIn()
  }

  componentWillUnmount () {
    this.unmounted = true
    this.resizeObserver.disconnect()
  }

  autoFitCanvas = () => {
    this.canvas.width = this.container.current.clientWidth
    this.canvas.height = this.container.current.clientHeight
  }

  startTransitionIn = () => {
    this.startTimer = performance.now()
    this.firstFrame = true
    this.direction = 1
    setTimeout(() => {
      this.ctx.fillStyle = "black"
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      this.setState(state => ({
        ...state,
        mounted: true,
      }))
      this.loop()
    }, 0);
  }

  startTransitionOut = () => {
    this.startTimer = performance.now()
    this.firstFrame = true
    this.direction = -1
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.loop()
  }

  renderTransition = (t) => {
    const w = this.canvas.width
    const h = this.canvas.height
    const hb = 30
    const rect = this.direction === 1 ? (...args) => this.ctx.clearRect(...args) : (...args) => this.ctx.fillRect(...args)
    
    // horizontal bands 
    for (let y = 0; y < h; y+=hb) {
      const delay = snoise(y, 0) * .4 + .4
      const tb = Math.max(t - delay, 0) / (1 - delay)
      const wt = w * tb
      rect(0, y, wt, hb)
    }
  }

  loop = () => {
    if (!this.unmounted) {
      const time = performance.now()
      let t;
      
      // prevents first value of t from being != 0
      if (this.firstFrame) {
        this.firstFrame = false
        t = 0
      }
      else {
        t = (time - this.startTimer) / duration
      }
  
      if (t <= 1) {
        requestAnimationFrame(this.loop)
        this.renderTransition(t)
      }
      else {
        if (this.direction === 1) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.props.onTransitionInEnd && this.props.onTransitionInEnd()
        }
        else {
          this.ctx.fillStyle = "black"
          this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
          this.props.onTransitionEnd && this.props.onTransitionEnd()
        }
      }
    }
  }

  render () {
    return (
      <StCont ref={this.container} mounted={this.state.mounted}>
        <canvas ref={this.canvasRef} />
      </StCont>
    )
  }
}

export default MountingEffectComponent