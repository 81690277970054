import * as THREE from "three"
import DOMtoGLTexture from "Utils/GL/DOMtoGLTexture"
import quadVS from "Utils/GL/shaders/quad.vert.glsl"
import blocksFS from "Utils/GL/shaders/blocks.frag.glsl"
import rdInitFS from "Utils/GL/shaders/rd-init.frag.glsl"
import rdFS from "Utils/GL/shaders/rd.frag.glsl"
import rdRenderFS from "Utils/GL/shaders/rd-render.frag.glsl"
import textureFS from "Utils/GL/shaders/texture.frag.glsl"

import Experiment from "Core/Experiment"
import ArtisticExpressionComponent from "./Component"


const RD_SETTINGS = {
  _DiffRateB: { min: 0.4, max: 0.2 },
  _FeedRate: { min: 0.055, max: 0.06 },
  _KillRate: { min: 0.062, max: 0.073 },
}

class ArtisticExpression extends Experiment {
  isRunning = false
  mouseX = window.innerWidth * .5
  mouseY = window.innerHeight * .5

  // the RD buffers
  rdBuffer1 = null
  rdBuffer2 = null

  // if a value is set it will force the killrate
  forcedKR = false

  constructor () {
    super()
    this.loop = this.loop.bind(this)
  }

  /**
   * 
   * @param {HTMLElement} element 
   */
  init (element, canvas, feedElements, killElements) {
    super.init(element)

    return new Promise((resolve) => {
      this.notifyOnLoadCallbacks(0.1)

      this.canvas = canvas
      const w = canvas.width
      const h = canvas.height
      const w4 = (w/4)|0
      const h4 = (h/4)|0

      // init renderer
      this.renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        powerPreference: "high-performance",
        alpha: true,
        preserveDrawingBuffer: true,
      })
      this.renderer.setClearColor(0xffffff, 0)

      this.notifyOnLoadCallbacks(0.15)

      // init the DOM textures, then the experiment
      this.DOMtextureF = new DOMtoGLTexture()
      this.DOMtextureF.init(this.renderer, feedElements, w4, h4)
      this.DOMtextureK = new DOMtoGLTexture()
      this.DOMtextureK.init(this.renderer, killElements, w4, h4)
      this.notifyOnLoadCallbacks(0.3)

      // the scene & camera
      this.scene = new THREE.Scene()
      this.camera = new THREE.OrthographicCamera(-w / 2, w / 2, h / 2, -h / 2, 0.1, 100)
      this.camera.position.z = 1

      // creates 2 buffers for the RD 
      this.bufferRD1 = new THREE.WebGLRenderTarget(w4, h4, { 
        minFilter: THREE.LinearFilter, 
        magFilter: THREE.LinearFilter,
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
      })
      this.bufferRD2 = new THREE.WebGLRenderTarget(w4, h4, { 
        minFilter: THREE.LinearFilter, 
        magFilter: THREE.LinearFilter,
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
      })

      // the material for RD initialization
      this.initRDmat = new THREE.RawShaderMaterial({
        vertexShader: quadVS,
        fragmentShader: rdInitFS,
      })

      // the quad mesh will be used for initialization and then for drawing
      this.quadMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(), this.initRDmat)
      this.scene.add(this.quadMesh)

      // initialize the RD texture to the buffer 2
      this.renderer.setRenderTarget(this.bufferRD2)
      this.renderer.render(this.scene, this.camera)
      this.renderer.setRenderTarget(null)

      this.notifyOnLoadCallbacks(0.85)

      // the RD material
      this.rdMat = new THREE.RawShaderMaterial({
        uniforms: {
          _LastFrameTexture: { value: this.bufferRD2 },
          _FeedTexture: { value: this.DOMtextureF.texture },
          _KillTexture: { value: this.DOMtextureK.texture },
          _DiffRateA: { value: 1 },
          _DiffRateB: { value: RD_SETTINGS._DiffRateB.min },
          _FeedRate: { value: RD_SETTINGS._FeedRate.min },
          _KillRate: { value: RD_SETTINGS._KillRate.min },
          _Resolution: { value: new THREE.Vector2(w4, h4) },
          _Time: { value: 0 },
        },
        vertexShader: quadVS,
        fragmentShader: rdFS,
      })

      // the full quad object
      this.quadMaterial = new THREE.RawShaderMaterial({
        uniforms: {
          uTexture: { value: this.bufferRD2 },
          uResolution: { value: new THREE.Vector2(w4, h4) },
          uWindow: { value: new THREE.Vector2(w, h) },
          uMouse: { value: new THREE.Vector2(0.5, 0.5) },
          uLightStrength: { value: 1 },
        },
        vertexShader: quadVS,
        fragmentShader: rdRenderFS,
      })
      this.quadMesh.material = this.quadMaterial
      this.quadMesh.material.needsUpdate = true

      this.notifyOnLoadCallbacks(0.95)

      // setup listeners
      document.addEventListener("mousemove", this.onMouseMoveDocument)
      document.addEventListener("mousedown", this.onMouseDownDocument)
      window.addEventListener("scroll", this.onScroll)

      this.notifyOnLoadCallbacks(1)
      resolve()
    })
  }

  onMouseMoveDocument = (event) => {
    this.mouseX = event.clientX 
    this.mouseY = event.clientY
  }

  onMouseDownDocument = () => {
  }

  onScroll = (event) => {
    const docH = document.documentElement.scrollHeight - window.innerHeight
    const scroll = window.scrollY
    const R = scroll / docH

    for (const key in RD_SETTINGS) {
      this.rdMat.uniforms[key].value = RD_SETTINGS[key].min + R * (RD_SETTINGS[key].max-RD_SETTINGS[key].min)
    }
  }

  setLightsOff = () => {
    return new Promise(resolve => {
      let active = true
      const fadeLightsOut = () => {
        if (active) requestAnimationFrame(fadeLightsOut)
        this.quadMaterial.uniforms['uLightStrength'].value*= 0.94
        if (this.quadMaterial.uniforms['uLightStrength'].value < 0.1) {
          this.quadMaterial.uniforms['uLightStrength'].value = 0
          active = false
          resolve()
        }
      }
      fadeLightsOut()
    })
  }

  hideBlocks = () => {
    this.DOMtextureF.hideBlocks()
    this.DOMtextureK.hideBlocks()
  }

  // will get called by the component
  onWindowResize = (width, height) => {
    this.renderer.setSize(width, height)

    // todo: trigger the resize of the render targets

    // this.DOMtextureF.update()
    // this.DOMtextureK.update()
  }

  start () {
    super.start()
    this.isRunning = true
    this.loop()
  }

  /**
   * Analyze the canvas to see the percentage of black pixels (rough estimate but that's enough)
   */
  transitionEndAnalyzer = () => {
    return new Promise(resolve => {
      const cvs = document.createElement("canvas")
      const ctx = cvs.getContext("2d")
      cvs.width = (this.canvas.width/10)|0
      cvs.height = (this.canvas.height/10)|0
      let history = [255, 255, 255, 255, 255]

      const interval = setInterval(() => {
        ctx.drawImage(this.renderer.domElement, 0, 0, cvs.width, cvs.height)
        const data = ctx.getImageData(0, 0, cvs.width, cvs.height).data
        let avg = 0

        // rough estimate of the average color 
        for (let i = 0; i < 40; i++) {
          let idx = (Math.random() * cvs.width * cvs.height) | 0
          avg+= data[idx*4] + data[idx*4 + 1] + data[idx*4 + 1]
        }
        avg/= 3*40
        
        // we use an history to reduce the error margin
        history.shift()
        history.push(avg)
        const white = history.reduce((a, b) => a+b)
        if (white < 0.5) {
          clearInterval(interval)
          resolve()
        }
      }, 150)
    })
  }

  stop () {
    super.stop()
    this.isRunning = false
  }

  loop () {
    if (this.isRunning) {
      requestAnimationFrame(this.loop)
      super.loop()  // compute the timers

      this.DOMtextureF.update()
      this.DOMtextureK.update()

      // swap the 2 buffers
      let tmp = this.bufferRD2
      this.bufferRD2 = this.bufferRD1
      this.bufferRD1 = tmp

      // render RD to the 2nd buffer
      if (this.forcedKR !== false) {
        this.rdMat.uniforms['_KillRate'].value = this.forcedKR
      }
      this.rdMat.uniforms['_LastFrameTexture'].value = this.bufferRD1
      this.rdMat.uniforms['_Time'].value = this.elapsed * 0.001
      this.quadMesh.material = this.rdMat
      this.quadMesh.material.needsUpdate = true
      this.renderer.setRenderTarget(this.bufferRD2)
      this.renderer.render(this.scene, this.camera)
      this.renderer.setRenderTarget(null)

      // 2nd pass
      tmp = this.bufferRD2
      this.bufferRD2 = this.bufferRD1
      this.bufferRD1 = tmp
      this.rdMat.uniforms['_LastFrameTexture'].value = this.bufferRD1
      this.quadMesh.material = this.rdMat
      this.quadMesh.material.needsUpdate = true
      this.renderer.setRenderTarget(this.bufferRD2)
      this.renderer.render(this.scene, this.camera)
      this.renderer.setRenderTarget(null)

      // render final scene
      const pvMouse = this.quadMaterial.uniforms['uMouse'].value
      this.quadMaterial.uniforms['uMouse'].value = new THREE.Vector2(
        pvMouse.x + 0.5 * (this.mouseX/window.innerWidth - pvMouse.x), 
        pvMouse.y + 0.5 * (this.mouseY/window.innerHeight - pvMouse.y), 
      )
      this.quadMaterial.uniforms['uTexture'].value = this.bufferRD2
      this.quadMesh.material = this.quadMaterial
      this.quadMesh.material.needsUpdate = true

      this.renderer.clear()
	    this.renderer.render(this.scene, this.camera)
    }
  }

  destroy () {
    return new Promise(resolve => {
      this.DOMtextureF.destroy()
      this.DOMtextureK.destroy()

      document.removeEventListener("mousemove", this.onMouseMoveDocument)
      document.removeEventListener("mousedown", this.onMouseDownDocument)
      window.removeEventListener("scroll", this.onScroll)

      resolve()
    })
  }
}

ArtisticExpression.link = "/artistic-expression"
ArtisticExpression.title = "The artistic expression of a document"
ArtisticExpression.description = "Bringing a sense of life to a mechanical entity "
ArtisticExpression.component = ArtisticExpressionComponent

export default ArtisticExpression