export default {
  articles: [
    {
      type: "feed",
      content: `This experiment is an attempt at giving an artistic expression to the elements of the DOM. As the user goes through this document, the DOM elements will have a direct incidence on the visuals generated in the background.`
    },
    {
      type: "feed",
      content: `Some blocks <strong>spread some substrate</strong> into the simulation.`
    },
    {
      type: "kill",
      content: `Others will <strong>kill the substrate</strong> as they cross it. There is no need to identify them visually as the interaction with the virtual environment alone is enough for this trait to be deduced.`
    },
    {
      type: "feed",
      content: `As the page is being scrolled, the settings of the simulation will change, emphasizing the idea of a page responding to external stimulus. It also provides enough variations for it not to be too linear.`
    },
    {
      type: "kill",
      content: `To process the information of the page, the user has to scroll through it as he is used to. Because only the elements of the DOM can interact with the simulation, he must interact with the document in a natural manner to have some control over the visuals. Instead of giving him a direct control, having the DOM acting as a bridge to communicate with it gives the user the impression that the document expresses itself by giving feedback through the visuals.`
    },
    {
      type: "kill",
      content: `Only the light can be moved with the mouse: the user is only a spectator until he interacts with the document.`
    },
    {
      type: "feed",
      content: `All this elements are, in a way, giving some sort of artistic expression to the document. Of course, in the end, this is only the result of an experience carefully crafted by a creative developer, but the materialization of his efforts gives both the user and the DOM a means to communicate with the virtual environment.`
    },
    {
      type: "kill",
      content: `To learn more about the underlying mechanics of this experiment, please have a look at the technical overview.`
    },
  ]
}