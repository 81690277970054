import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import DomToEnv from "Utils/DomToEnv"
import { useAnimationFrame, useInScreen, useMouse } from "Utils/Hooks"
import FakeWebPage from "../FakeWebPage"
import Node from "Experiments/Perturbations/Physics/Node"
import { lerp } from "Utils/maths"


const StCont = styled.div`
  width: 100%;
  border: 2px solid black;
  position: relative;

  canvas {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  .page-wrapper {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .page {
    width: 25%;
    margin-right: 15px;
    padding: 300px 0 300px;
    font-size: 8px;
    line-height: normal;
    z-index: 100;

    p {
      background: white;
      padding: 15px;
    }

    .anchor {
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
`

const nbPoints = 6

const Example3 = () => {
  const container = useRef()
  const canvasRef = useRef()
  const nodes = useRef()
  const anchorRef = useRef()
  const scrollableRef = useRef()
  const accYsmooth = useRef(0)

  useEffect(() => {
    const cont = container.current
    cont.style.height = (cont.clientWidth * 9 / 16) + 'px'

    const cvs = canvasRef.current
    cvs.width = cont.clientWidth
    cvs.height = cont.clientWidth * 9 / 16

    // find start and end coordinates
    const startX = cvs.width * .2
    const Y = cvs.height * .5
    const endX = cvs.width * .8

    // initialize the nodes
    nodes.current = []
    for (let i = 0; i < nbPoints; i++) {
      let X = lerp(startX, endX, i / nbPoints)
      nodes.current[i] = new Node(X, Y, i === 0 || i === nbPoints-1)
    }

    // and their links
    for (let i = 1; i < nbPoints-1; i++) {
      nodes.current[i].addEdge(nodes.current[i-1])
      nodes.current[i].addEdge(nodes.current[i+1])
    }
    nodes.current[0].addEdge(nodes.current[1])
    nodes.current[nbPoints-1].addEdge(nodes.current[nbPoints-2])

    return () => {
    }
  }, [])

  const inScreen = useInScreen(canvasRef)
  const mouse = useMouse(-100, -100)

  useAnimationFrame((dt, t) => {
    if (inScreen.current) {
      const cvs = canvasRef.current
      const ctx = cvs.getContext('2d')

      const ctBounds = cvs.getBoundingClientRect()

      const mouseX = mouse[0].current - ctBounds.x
      const mouseY = mouse[1].current - ctBounds.y
  
      ctx.fillStyle = "#000000"
      ctx.fillRect(0, 0, cvs.width, cvs.height)

      // fix the right node to the anchor
      const ancBd = anchorRef.current.getBoundingClientRect()
      const fx = ancBd.x - ctBounds.x
      const fy = ancBd.y - ctBounds.y
      const lastNode = nodes.current[nodes.current.length-1]
      lastNode.pos.x = fx
      lastNode.pos.y = fy

      // update the acc from links
      for (const node of nodes.current) {
        node.updateLinksForces(0.05)
        node.updateMouse(mouseX, mouseY, 50, 12)
      }

      // strore acceleration Y for latter usage
      accYsmooth.current = lerp(accYsmooth.current, lastNode.acc.y, 0.1)
      const accY = accYsmooth.current

      // updates pos from acc
      for (const node of nodes.current) {
        node.update(dt*0.005, false)
      }

      // update scroll based on the acc Y
      let scroll = scrollableRef.current.scrollTop
      scrollableRef.current.scrollTo(0, scroll - accY*0.01*dt)

      // draw mouse
      ctx.fillStyle = 'yellow'
      ctx.beginPath()
      ctx.arc(mouseX, mouseY, 50, 0, 2*Math.PI)
      ctx.fill()

      // draw the links
      ctx.strokeStyle = 'red'
      ctx.lineWidth = 2
      for (const node of nodes.current) {
        for (const edge of node.edges) {
          ctx.beginPath()
          ctx.moveTo(node.pos.x, node.pos.y)
          ctx.lineTo(edge.pos.x, edge.pos.y)
          ctx.stroke()
        }
      }

      // draw the nodes
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)'
      ctx.fillStyle = 'white'
      for (const node of nodes.current) {
        ctx.beginPath()
        ctx.arc(node.pos.x, node.pos.y, 10, 0, 2*Math.PI)
        ctx.stroke()
        ctx.beginPath()
        ctx.arc(node.pos.x, node.pos.y, node.fixed ? 10 : 3, 0, 2*Math.PI)
        ctx.fill()
      }

      // draw the last node acceleration y component as a vector
      ctx.strokeStyle = "#00ff00"
      ctx.lineWidth = 4
      ctx.save()
      ctx.translate(lastNode.pos.x - 20, lastNode.pos.y)
      ctx.beginPath()
      ctx.moveTo(0, 0)
      ctx.translate(0, accY)
      ctx.lineTo(0, 0)
      ctx.stroke()
      ctx.rotate(Math.PI*.25*(-Math.sign(accY)))
      ctx.beginPath()
      let l = Math.abs(accY * .1)
      ctx.moveTo(-2, 0)
      ctx.lineTo(l, 0)
      ctx.stroke()
      ctx.rotate(Math.PI*.5*(-Math.sign(accY)))
      ctx.beginPath()
      ctx.moveTo(-2, 0)
      ctx.lineTo(l, 0)
      ctx.stroke()
      ctx.restore()
    }
  })

  return (
    <StCont ref={container}>

      <canvas ref={canvasRef} />

      <div className="page-wrapper" ref={scrollableRef}>
        <div className="page">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec et odio pellentesque diam. Ipsum dolor sit amet consectetur adipiscing. Tincidunt arcu non sodales neque sodales ut etiam sit. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt. Et tortor at risus viverra adipiscing at in tellus integer. Fusce ut placerat orci nulla pellentesque dignissim enim sit. Quisque egestas diam in arcu cursus euismod quis viverra. Ipsum a arcu cursus vitae congue mauris. Fringilla ut morbi tincidunt augue. Eu mi bibendum neque egestas congue quisque egestas diam. Nullam ac tortor vitae purus. Sem et tortor consequat id porta nibh venenatis cras. Id leo in vitae turpis massa sed. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Dolor sit amet consectetur adipiscing.
          </p>

          <p style={{ position: 'relative' }}>
            Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Ultrices mi tempus imperdiet nulla malesuada. Tortor consequat id porta nibh venenatis cras. Placerat vestibulum lectus mauris ultrices. Enim neque volutpat ac tincidunt vitae semper. Imperdiet sed euismod nisi porta. Velit dignissim sodales ut eu sem integer vitae justo eget. Odio facilisis mauris sit amet massa vitae. Egestas dui id ornare arcu odio ut sem nulla pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada. Bibendum at varius vel pharetra vel. Amet risus nullam eget felis eget nunc lobortis mattis. Neque sodales ut etiam sit. Sed risus pretium quam vulputate. Vulputate eu scelerisque felis imperdiet proin fermentum. Purus sit amet luctus venenatis lectus. Ac turpis egestas sed tempus. Interdum varius sit amet mattis vulputate. Sapien et ligula ullamcorper malesuada proin.
            <span className="anchor" ref={anchorRef} />
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec et odio pellentesque diam. Ipsum dolor sit amet consectetur adipiscing. Tincidunt arcu non sodales neque sodales ut etiam sit. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt. Et tortor at risus viverra adipiscing at in tellus integer. Fusce ut placerat orci nulla pellentesque dignissim enim sit. Quisque egestas diam in arcu cursus euismod quis viverra. Ipsum a arcu cursus vitae congue mauris. Fringilla ut morbi tincidunt augue. Eu mi bibendum neque egestas congue quisque egestas diam. Nullam ac tortor vitae purus. Sem et tortor consequat id porta nibh venenatis cras. Id leo in vitae turpis massa sed. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Dolor sit amet consectetur adipiscing.
          </p>

          <p>
            Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Ultrices mi tempus imperdiet nulla malesuada. Tortor consequat id porta nibh venenatis cras. Placerat vestibulum lectus mauris ultrices. Enim neque volutpat ac tincidunt vitae semper. Imperdiet sed euismod nisi porta. Velit dignissim sodales ut eu sem integer vitae justo eget. Odio facilisis mauris sit amet massa vitae. Egestas dui id ornare arcu odio ut sem nulla pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada. Bibendum at varius vel pharetra vel. Amet risus nullam eget felis eget nunc lobortis mattis. Neque sodales ut etiam sit. Sed risus pretium quam vulputate. Vulputate eu scelerisque felis imperdiet proin fermentum. Purus sit amet luctus venenatis lectus. Ac turpis egestas sed tempus. Interdum varius sit amet mattis vulputate. Sapien et ligula ullamcorper malesuada proin.
          </p>
        </div>
      </div>

    </StCont>
  )
}

export default Example3