import React from "react"
import styled from "styled-components"
import Example from "./Example"


const DomToGL = () => {
  return (
    <>
      <h2 id="dom-to-gl">2. DOM to a GL texture</h2>

      <p>
        The 2nd experiment uses a Reaction-Diffusion system to generate the patterns. It would be too expensive to have a fullscreen effect such as this one running on the CPU. So it had to be done on the GPU. I won’t detail all the mechanisms behind the Reaction-Diffusion on a GPU, if you are interested in the subject you might look at <a href="https://ciphrd.com/2019/08/24/reaction-diffusion-on-shader/" target="_blank">an article I wrote about it</a> on my blog. It is possible to “feed” patterns to the system, and one way to do it is to have an insertion texture which is the same size as the environment, which will be sampled during the application of the reaction-diffusion.
      </p>

      <p>
        But because it runs on the GPU, the texture needs to be a GL buffer for it to be sampled. The best way to tackle this problem is to have a fragment shader generate the insertion texture, and reference this texture to the fragment shader doing the Reaction-Diffusion. The same technique as the one described in the first section is used to get the coordinates of the visible element. There are 4 values for each rectangle: x, y, width and height. We can send those values as a RGBA Float32 buffer to the fragment shader, each "pixel" of the texture buffer representing a rectangle. The buffer is initialized at a given size (16x1 in my case), so that the fragment shaders knows the maximum number of rectangles it can expect (it helps for optimization puposes, see <em>unrolling loop</em>). The coordinates should be <strong>normalized</strong> in viewport space to reduce the computations on the GPU. Then, the fragment shader will output a texture with the corresponding rectangles on it (<a href="https://github.com/bcrespy/dom-art-proposal/blob/master/src/Utils/GL/shaders/blocks.frag.glsl" target="_blank">see source code</a>). The output texture can then be used by any shader, in this case the RD shader.
      </p>

      <p>
        The followings example demonstrates how the visible element bounds are first converted as a Float32 RGBA buffer, and the output of the “DOM to texture” fragment shader:
      </p>

      <div className="example">
        <Example />
      </div>

      <p>
        On a side note, you may have noticed similarities between these first 2 steps and the previous ones. It means that some logic can be abstracted. I wrote a a generic-purpose class to handle such feature: if initialized with a list of elements and a container, calling its update method will return a list of the 2D bounds of the elements visible within the container. If no container is supplied, the whole viewport will be used.
      </p>
    </>
  )
}

export default DomToGL