import React from "react"
import styled from "styled-components"
import Example from "./Example"


const DomToEnv = () => {
  return (
    <>
      <h2 id="dom-to-env">1. From the DOM to a virtual environment</h2>

      <p>
        An idea shared by experiments 1, 2, 3 and 5 was to use the bounding boxes of some elements of the DOM as inputs to a virtual environment. To achieve this, I chose the following approach:
      </p>

      <ul>
        <li>mark some elements of the DOM as “interactive elements”: these are the elements which will have a physical presence in the virtual environment</li>
        <li>at each frame, during the main animation loop, find the elements visible on the screen</li>
        <li>compute a list of the bounding rectangles corresponding to the visible elements</li>
        <li>use this list as an input to autonomous system; this step differs depending on the usage made by each experiment</li>
      </ul>

      <p>
        At a first glance, it seems that computing the bounds of the elements at each frame must be overkill. After all, the boxes should only change if the user scrolls the page or resizes the window. Maybe it should be possible to listen for the occurrences of those events and only update the boxes when they get triggered. This solution, however, came with 2 major caveats:
      </p>

      <ul>
        <li>
          if the elements get moved (for instance the fake windows of experiment 1), the events wouldn’t happen and thus the bounds computation had to be triggered manually. This is fine but becomes problematic for a generic-purpose solution.
        </li>
        <li>
          if expensive computations have to be done after an update of the bounds, the user could experience lags because the addition of computations would create inconsistencies in the time of execution of the main loop. I would rather have a constant slower frame rate than an inconsistent one.
        </li>
      </ul>

      <p>
        The following demonstration shows this technique being used to compute the bounds relative to the viewport. The bounds are then drawn onto a canvas, but anything can be done with it.
      </p>

      <div className="example">
        <Example />
      </div>

      <p>
        One optimization could be made to improve this solution. As described, regardless of the position of the element relative to the viewport, even if completely outside, computations are made to see if the element is on the screen. The <a href="https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API" target="_blank">Intersection Observer</a> API provides a way to listen to events triggered by a change in the bounding intersection between any element and the viewport. By using this API, it is possible to keep a list of only the visible elements, and thus skip the visibility test made on each marked element. This solution seemed useless for my use-case since there are only a small amount of elements to keep track of in the first place.
      </p>
    </>
  )
}

export default DomToEnv