import React, { useEffect, useState } from "react";
import styled from "styled-components";


const StCont = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  transition: all .3s;
  transform: scale(1);

  ${props => props.hidden && `
    pointer-events: none;
    opacity: 0;
    transform: scale(1.2);
  `}
`

const StLoader = styled.div`
  width: 300px;
  height: 10px;
  border: 1px solid white;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    height: 100%;
    width: ${props => props.progress*100}%;
    transition: all 1s;
  }
`

const ExperimentLoader = ({ progress }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (progress >= 1) {
      setHidden(true)
    }
  }, [progress])

  return (
    <StCont hidden={hidden}>
      <StLoader
        progress={progress}
      />
    </StCont>
  )
}

export default ExperimentLoader