/**
 * An "experiment" is a class providing an interface for the "engine" of the experiment and the rest of the application.
 * It should provide a set of methods in that matter.
 */

class Experiment {
  // an array of functions to call during the experiment initialisation
  onLoadCallbacks = []

  // timers, in ms
  elapsed = 0
  dt = 0
  absoluteT = 0

  // reference to DOM container
  container = null

  constructor () {}

  /**
   * Register a callback which will be called during the initialisation of the experiment
   * @param {Function} callback 
   */
  registerOnLoadCallback (callback) {
    this.onLoadCallbacks.push(callback);
  }

  /**
   * Notifies all the "onload" callbacks with the progress of the loading
   * @param {number} progress [0; 1] a value between 0 and 1 representing the state of the progress of the loading
   */
  notifyOnLoadCallbacks (progress) {
    for (const callback of this.onLoadCallbacks) {
      callback(progress);
    }
  }

  /**
   * @abstract
   * Will be called by the Loader to initialize the application. Any intensive task should be processed here
   * @param {HTMLElement} container the element in which the experiment can interact with
   */
  init (container) {
    this.container = container
  }

  /**
   * @abstract
   * Starts the experiment, will be called by the loader after initialization and any operation the Loader needs to perform
   * before the experiment can start (such as hiding a loader viewer for instance)
   */
  start () {
    this.elapsed = 0
    this.dt = 0
    this.absoluteT = performance.now()
  }

  /**
   * Provides timings calculations, can be called by children to delegate such a task
   */
  loop () {
    let t = performance.now()
    this.dt = Math.min(30, t - this.absoluteT)
    this.absoluteT = t
    this.elapsed+= this.dt
  }

  /**
   * @abstract
   * Stops the experiment, but shouldn't free the resources
   */
  stop () {}

  /**
   * @abstract
   * Will be called once the experiment can safely free any resource required. Freeing resources is important because leaks
   * could interfere with the other experiments which will later be loaded as the user navigates between them.
   */
  destroy () {}
}

export default Experiment