import React from "react"
import styled from "styled-components"


const StButton = styled.button`
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-variation-settings: 'wght' 300;
  transition: all .3s;
  
  &:hover {
    font-variation-settings: 'wght' 900;
  }
  
  &:active {
    background: red;
    transform: scale(1.1);
  }
`

const Button = (props) => (
  <StButton {...props} />
)

export default Button