import React from "react"
import styled from "styled-components"
import Page from "Components/Page"



const Introduction = () => {
  return (
    <Page title="An introduction to DOM art">
      <p>
        We, as humans, designed a very particular place that is the Web. While its very existence remains stored on physical drives, its essence takes the form of interconnected matrices of pixels. Each human, either alone or within a team of many others, can decide what those matrices will display when a user requests an access to it and can decide to which other matrices each matrix will point to. In other words, to which pages a page will link to from the clickable elements displayed on the user device.
      </p>
      <p>
        We had to design, over the years, a worldwide architecture capable of storing and exchanging astronomic amounts of data, in a very fast and reliable way. Softwares running on devices, the internet browsers, give us easy access to this architecture. We have reached a point where we are able to deliver to almost every human on the planet more data than he will ever be able to consume, from a device in its pocket. We can deliver the content as fast as it is consumed. We were once limited by the time it took to load the data but now the limit is the ability of our brains to process such data. We could deliver it faster, every video could be played twice as fast for instance, but we don’t need to because our brains won’t follow.
      </p>
      <p>
        To reach this particular limit, we have designed many elaborated technologies and conceptualized many standards so that every device could speak the same language and display information the same way. Over the years, as the computational power of the devices grew, more and more tools were made available for web developers to build more and more diverse experiences, still in a predictable way. Even in a more predictable way, because many efforts were put in the standardization of the techniques made available by browsers API to display data on user screens and interact with the device components. 
      </p>
      <p>
        For instance, the DOM was designed to represent the structure and content of a document on the web. The DOM tells the browser what content there is to display and how to display it. It can also tell the browser what scripts it needs to load with the DOM and the browser provides to those scripts many APIs either to manipulate/read the DOM, or to exchange data with components and tools of such devices. Developers can use the scripts to add some small behaviors to static pages, but they can also use them to run code with a javascript engine which handles the whole generation of the DOM. As many websites today, the DOM of this website is updated on-the-fly as you interact with it, by the scripts only.
      </p>
      <p>
        Because of the many tools developers have access to, we can craft very diverse experiences using many rendering techniques. Because we also have access to the graphic card with WebGL, we can render computational-heavy graphics on the CPU at a very low cost on the GPU. Rendering graphics with the CPU is also now very fast, and is sometimes more suitable than using the GPU. Browsers can even read and display SVGs very well; this is another suitable technique for rendering animated graphics in real time. Even plain CSS can be used in that regard. The point being: web developers have access to very powerful tools to render graphics in real time, as well as a very direct access to the user input.
      </p>
      <p>
        Since the rise of computers, the usage of autonomous systems to create art pieces has grown in popularity among artists. This is a practice called Generative Art. Carefully crafted programs can be used to explore virtual environments, in which precise rules and eventual randomness work together to output visuals. Autonomous systems running in real time are perfect candidates to bring creativity to websites. Because users naturally interact with documents of the web, it really becomes easy to connect their input to generative artworks. 
      </p>
      <p>
        This project is a collection of experiments in which the DOM rendered on the screen, as well as data from the keyboard or the mouse, are used as inputs to virtual environments generated in real time and layered along the DOM. This particular focus seems interesting to explore because the data displayed on the screen makes the first essence of a website. Adding to this data a new layer of graphics and having them both interact with each other gives the ability to add information to some already meaningful one, thus crafting more elaborated experiences for the user. The name “DOM art” is nothing but a proposal to introduce a terminology around this concept.
      </p>
    </Page>
  )
}

export default Introduction