import React from "react"
import styled from "styled-components"

import Page from "Components/Page"
import DomToEnv from "./DomToEnv"
import DomToGL from "./DomToGL"
import PhysicsScroll from "./PhysicsScroll"
import RDTtransition from "./RDTransition"


const Technical = () => {
  return (
    <Page title="Technical overview of the experiments">

      <p>
        The technical overview will give some insights about how the experiments were made. Because it would be too long to write all the details, only the most creative solutions will be explained. Because some experiments share the same techniques, the overview will not describe every experiment but rather explain the core concepts behind them.
      </p>

      <h2>Summary</h2>

      <nav>
        <ol>
          <li><a href="#dom-to-env">From the DOM to a virtual environment</a></li>
          <li><a href="#dom-to-gl">DOM to a GL texture</a></li>
          <li><a href="#physics-scroll">Hacking the scroll with a physics simulation</a></li>
          <li><a href="#rd-transition">The timing issue of the Reaction-Diffusion transition</a></li>
        </ol>
      </nav>

      <DomToEnv />

      <DomToGL />

      <PhysicsScroll />

      <RDTtransition />

    </Page>
  )
}

export default Technical