import React, { useEffect } from "react"
import styled from "styled-components"
import MountingEffect from "./MountingEffect"


const StTitle = styled.h2`
  padding: 15px 50px;
  border-left: 3px solid white;
  border-bottom: 2px solid white;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    transform: translateX(-8px) rotate(315deg);
  }
`

const StContent = styled.article`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: calc(50vh + 50px);

  p, ul {
    margin-top: 80px;
    padding: 20px;
  }

  ul {
    margin-left: 5px;
  }

  li {
    padding: 20px;
  }

  li:not(:first-child) {
    margin-top: 0px;
  }
`

const Page = React.forwardRef(({ title, component, onLoad, onTransitionInEnd, onTransitionEnd }, ref) => {
  const Comp = component

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <>
      <StTitle>{ title }</StTitle>
      <StContent>
        <Comp />
      </StContent>
      <MountingEffect 
        ref={ref} 
        onTransitionEnd={onTransitionEnd}
        onTransitionInEnd={onTransitionInEnd}
      />
    </>
  )
})

export default Page