import Experiment from "Core/Experiment"
import Easings from "@creenv/easings"
import NaturalInputsExperimentComponent from "./Component"
import { lerp } from "Utils/maths"


const FOCUS_DURATION = 2000


const rd = (x) => (Math.random()-0.5) * x


class NaturalInputsExperiment extends Experiment {
  isRunning = false
  mouseX = window.innerWidth * .5
  mouseY = window.innerHeight * .5

  /**
   * The element on which the user focus might be
   * @type {HTMLElement}
   */
  activeElement = null
  // keep track of hovered candidate to prevent unnecessary computations
  lastHoveredCand = null

  // for abimations
  timeFocusEffect = -99999.
  previousFocusBounds = null

  /**
   * @type {CanvasRenderingContext2D}
   */
  ctx = null
  canvas = null

  constructor () {
    super()
    this.loop = this.loop.bind(this)
  }

  /**
   * 
   * @param {HTMLElement} element 
   */
  init (element, canvas) {
    super.init(element)

    return new Promise((resolve) => {
      this.canvas = canvas
      this.ctx = canvas.getContext('2d')

      this.autoUpdateActiveElement()
      this.autoFitCanvas()

      // setup listeners
      document.addEventListener("mousemove", this.onMouseMoveDocument)
      document.addEventListener("mousedown", this.onMouseDownDocument)
      window.addEventListener("resize", this.onWindowResize)
      window.addEventListener("scroll", this.onScroll)

      this.notifyOnLoadCallbacks(1)
      resolve()
    })
  }

  onMouseMoveDocument = (event) => {
    this.mouseX = event.clientX 
    this.mouseY = event.clientY

    // console.log(event)

    // has the mouse crossed an element which can be focused ?
    let found = null
    for (const elem of event.path) {
      if (['P', 'LI'].includes(elem.tagName)) {
        // possibly, we need to update the active element
        found = elem
        if (!elem.isSameNode(this.lastHoveredCand)) {
          this.candidateHovered(elem)
        }
        break
      }
      if (elem.tagName === 'BODY') break
    }
    this.lastHoveredCand = found
  }

  onMouseDownDocument = (event) => {
  }

  onWindowResize = () => {
    this.autoFitCanvas()
  }

  onScroll = () => {
    this.autoUpdateActiveElement()
  }

  autoFitCanvas = () => {
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
  }

  autoUpdateActiveElement = () => {
    // get the list of blocks which are within the "focus" area
    const blocks = this.getCandidatesInFocusArea()

    // if we have at least 1, the first will be the first (highest union area)
    if (blocks.length > 0 && blocks[0].elem !== this.activeElement) {
      this.updateActiveElement(blocks[0].elem)
    }
  }

  /**
   * Updates the active element and triggers animations
   * @param {HTMLElement} elem the new active element
   */
  updateActiveElement = (elem) => {
    if (elem !== this.activeElement) {
      this.activeElement && this.activeElement.classList.remove('active')
      this.activeElement = elem
      this.activeElement.classList.add('active')

      this.triggerFocusEffect()

      if (elem !== this.lastHoveredCand) {
        this.lastHoveredCand = null
      }
    }
  }

  /**
   * Should be called if a candidate (element which may be one on which the focus is) was hovered. Tries to determine which
   * element has the focus based on this information. Updates the active element and triggers the animation
   * @param {HTMLElement} element
   */
  candidateHovered = (element) => {
    // get the list of blocks which are within the "focus" area
    const blocks = this.getCandidatesInFocusArea()

    // if there is a match between one result and the hovered we force it
    for (const b of blocks) {
      if (element.isSameNode(b.elem)) {
        this.updateActiveElement(element)
        break
      }
    }
  }

  /**
   * @returns {Array.<{elem: HTMLElement, area: number}>} an array of the html elements which are candidated on being focused 
   *          and have a part in the focus area
   */
  getCandidatesInFocusArea = () => {
    const cands = this.getCandidates()
    const areaY = window.innerHeight * 0.3
    const areaYmax = window.innerHeight - areaY
    const areaX = 0
    const areaXmax = window.innerWidth

    let bounds, xOverlap, yOverlap, surface
    let inFocus = []
    for (const cand of cands) {
      // checks if a part of a block is in the focus area
      bounds = cand.getBoundingClientRect()
      
      xOverlap = Math.max(0, Math.min(areaXmax,bounds.left+bounds.width) - Math.max(areaX,bounds.left))
      yOverlap = Math.max(0, Math.min(areaYmax,bounds.top+bounds.height) - Math.max(areaY,bounds.top));
      surface = (xOverlap * yOverlap) / (bounds.width * bounds.height)

      if (surface > 0) {
        inFocus.push({
          elem: cand,
          surface,
        })
      }
    }

    // elements in focus area sorted by surface of union area
    return inFocus.sort((a, b) => b.surface - a.surface)
  }

  /**
   * @returns {Array.<HTMLElement>} an array of the candidates within the container
   */
  getCandidates = () => {
    return this.container.querySelectorAll('p, li')
  }

  // starts the focus effect at given pos & size
  triggerFocusEffect = () => {
    this.timeFocusEffect = this.elapsed
  }

  renderFocusEffects = () => {
    // computes a timer based on duration & when last started € [0; 1]
    const t = 1 - Math.min(1, (this.elapsed - this.timeFocusEffect) / FOCUS_DURATION)
    if (t > 0) {  // we know we can render effects
      // get the bounds to render to
      let bounds = this.activeElement.getBoundingClientRect()
      if (!this.previousFocusBounds) this.previousFocusBounds = bounds
      bounds.x = lerp(this.previousFocusBounds.x, bounds.x, 0.1)
      bounds.y = lerp(this.previousFocusBounds.y, bounds.y, 0.1)
      bounds.width = lerp(this.previousFocusBounds.width, bounds.width, 0.1)
      bounds.height = lerp(this.previousFocusBounds.height, bounds.height, 0.1)
      this.previousFocusBounds = bounds

      const anim = this.activeElement.getAttribute("data-animation") || null

      switch (anim) {
        case 'default-red':
        {
          this.drawDefaultFocus(t, bounds, `rgba(255, 0, 0, 1)`)
          break
        }

        case 'default-holes':
        {
          this.drawDefaultFocusWithHoles(t, bounds, `rgba(255, 255, 255, 1)`)
          break
        }

        case 'intense':
        {
          this.drawIntenseFocus(t, bounds, `rgba(255, 255, 255, ${t})`)
          break
        }

        default:
        {
          this.drawDefaultFocus(t, bounds, `rgba(255, 255, 255, 1)`)
          break
        }
      }      
    }
  }

  drawDefaultFocus = (t, bounds, color) => {
    this.ctx.strokeStyle = color
    this.drawRandomizedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 10, t)
    this.drawRandomizedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 30, t)
  }

  drawDefaultFocusWithHoles = (t, bounds, color) => {
    this.ctx.strokeStyle = color
    this.drawHalvesRandomizedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 10, t)
    this.drawHalvesRandomizedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 30, t)
  }

  drawIntenseFocus = (t, bounds, color) => {
    let ti = Easings.cubicOut(Math.min(1, (1 - t) * 2))
    this.ctx.strokeStyle = `rgba(255, ${(ti*255)|0}, ${(ti*255)|0}, ${t})`
    const b = {
      x: ti * bounds.x,
      y: ti * bounds.y,
      width: lerp(window.innerWidth, bounds.width, ti),
      height: lerp(window.innerHeight, bounds.height, ti),
    }
    this.previousFocusBounds = b
    this.drawHalvesRandomizedRectangle(b.x, b.y, b.width, b.height, 10, t)
    this.drawHalvesRandomizedRectangle(b.x, b.y, b.width, b.height, 10 + (1-ti) * 200, t)
  }

  drawRandomizedRectangle = (x, y, w, h, rand, randomSides = 0.5) => {
    // top line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      this.ctx.moveTo(x + rd(rand), y + rd(rand))
      this.ctx.lineTo(x+w + rd(rand), y + rd(rand))
      this.ctx.stroke()
    }

    // bottom line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      this.ctx.moveTo(x + rd(rand), y + rd(rand) + h)
      this.ctx.lineTo(x+w + rd(rand), y + rd(rand) + h)
      this.ctx.stroke()
    }

    // left line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      this.ctx.moveTo(x + rd(rand), y + rd(rand))
      this.ctx.lineTo(x + rd(rand), y + h + rd(rand))
      this.ctx.stroke()
    }

    // right line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      this.ctx.moveTo(x + rd(rand) + w, y + rd(rand))
      this.ctx.lineTo(x + rd(rand) + w, y + h + rd(rand))
      this.ctx.stroke()
    }
  }

  drawHalvesRandomizedRectangle = (x, y, w, h, rand, randomSides = .4) => {
    let len;

    // top line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (w*.5)
      this.ctx.moveTo(x + rd(rand), y + rd(rand))
      this.ctx.lineTo(x + rd(rand) + len, y + rd(rand))
      this.ctx.stroke()
    }
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (w*.5)
      this.ctx.moveTo(x + rd(rand) + (w*.5) + len, y + rd(rand))
      this.ctx.lineTo(x + rd(rand) + w, y + rd(rand))
      this.ctx.stroke()
    }

    // bottom line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (w*.5)
      this.ctx.moveTo(x + rd(rand), y + rd(rand) + h)
      this.ctx.lineTo(x + rd(rand) + len, y + rd(rand) + h)
      this.ctx.stroke()
    }
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (w*.5)
      this.ctx.moveTo(x + rd(rand) + (w*.5) + len, y + rd(rand) + h)
      this.ctx.lineTo(x + rd(rand) + w, y + rd(rand) + h)
      this.ctx.stroke()
    }

    // left line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (h*.5)
      this.ctx.moveTo(x + rd(rand), y + rd(rand))
      this.ctx.lineTo(x + rd(rand), y + rd(rand) + len)
      this.ctx.stroke()
    }
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (h*.5)
      this.ctx.moveTo(x + rd(rand), y + rd(rand) + (h*.5) + len)
      this.ctx.lineTo(x + rd(rand), y + rd(rand) + h)
      this.ctx.stroke()
    }

    // right line
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (h*.5)
      this.ctx.moveTo(x + rd(rand) + w, y + rd(rand))
      this.ctx.lineTo(x + rd(rand) + w, y + rd(rand) + len)
      this.ctx.stroke()
    }
    if (Math.random() < randomSides) {
      this.ctx.beginPath()
      len = Math.random() * (h*.5)
      this.ctx.moveTo(x + rd(rand) + w, y + rd(rand) + (h*.5) + len)
      this.ctx.lineTo(x + rd(rand) + w, y + rd(rand) + h)
      this.ctx.stroke()
    }
  }

  start () {
    super.start()
    this.isRunning = true
    this.loop()
  }

  stop () {
    super.stop()
    this.isRunning = false
  }

  loop () {
    if (this.isRunning) {
      requestAnimationFrame(this.loop)
      super.loop()  // compute the timers

      // clear a bit
      this.ctx.fillStyle = 'rgba(0, 0, 0, 0.4)'
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      
      // render any focus effect
      this.renderFocusEffects()
    }
  }

  destroy () {
    return new Promise(resolve => {
      document.removeEventListener("mousemove", this.onMouseMoveDocument)
      document.removeEventListener("mousedown", this.onMouseDownDocument)
      window.removeEventListener("resize", this.onWindowResize)
      window.removeEventListener("scroll", this.onScroll)

      resolve()
    })
  }
}

NaturalInputsExperiment.link = "/natural-inputs"
NaturalInputsExperiment.title = "Natural user inputs for DOM interactions"
NaturalInputsExperiment.description = "Turning the user's natural flux of navigation into an input"
NaturalInputsExperiment.component = NaturalInputsExperimentComponent

export default NaturalInputsExperiment