import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import DomToGL from "Utils/GL/DOMtoGLTexture"
import { useAnimationFrame } from "Utils/Hooks"
import FakeWebPage from "../FakeWebPage"
import { WebGLRenderer, Scene, OrthographicCamera, RawShaderMaterial, Mesh, PlaneBufferGeometry } from "three"
import quadVS from "Utils/GL/shaders/quad.vert.glsl"
import textureFS from "Utils/GL/shaders/texture.frag.glsl"


const StCont = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
  & > * {
    flex-basis: 50%;
    padding: 15px;
  }

  .title {
    margin-bottom: 15px;
    display: block;
    font-size: 0.9em;
    line-height: normal;
    max-width: 80%;
  }

  .gl-buffer {
    width: 62px;

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      border: 1px solid black;
      height: 15px;

      & > div {
        width: 25%;
        height: 100%;
      }
    }
  }

  .window {
    width: 100%;
    padding-top: 66.6%;
    background: #eeeeee;
    position: relative;

    .window-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      border: 2px solid black;
      font-size: 5px;
    }
  }

  canvas {
    border: 2px solid black;
  }
`

const Example = () => {
  const container = useRef()
  const canvasRef = useRef()
  const [rectBounds, setRectBounds] = useState([])

  useEffect(() => {
    const cont = container.current
    canvasRef.current.width = cont.clientWidth
    canvasRef.current.height = cont.clientHeight

    // init GL stuff
    const renderer = new WebGLRenderer({
      canvas: canvasRef.current,
    })
    renderer.setSize(canvasRef.current.width, canvasRef.current.height)

    const scene = new Scene()
    const camera = new OrthographicCamera(0, 0, 1, 1, 0.1, 100)
    camera.position.z = 1

    // init DOM to GL
    const elems = cont.querySelectorAll('p')
    const domGL = new DomToGL(false)
    domGL.init(renderer, elems, canvasRef.current.width, canvasRef.current.height, cont)

    // init quad object for rendering
    const material = new RawShaderMaterial({
      uniforms: {
        uTexture: { value: domGL.texture },
      },
      vertexShader: quadVS,
      fragmentShader: textureFS,
    })
    const mesh = new Mesh(new PlaneBufferGeometry(), material)
    scene.add(mesh)

    const onScroll = () => {
      const bounds = domGL.getVisibleBlocks()
      setRectBounds(bounds)
      
      // update the DOM texture & render
      domGL.update()
      renderer.render(scene, camera)
    }

    onScroll()
    cont.addEventListener('scroll', onScroll)

    return () => {
      cont.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <StCont>

      <div>
        <span className="title">1. The paragraphs (white background) are marked as <em>interractive</em></span>

        <div className="window">
          <div className="window-wrapper" ref={container}>
            <FakeWebPage />
          </div>
        </div>
      </div>

      <div>
        <span className="title">2. The bounding rectangles of the visible marked elements are computed</span>
        <table>
          <thead>
            <tr>
              <td>x</td>
              <td>y</td>
              <td>width</td>
              <td>height</td>
            </tr>
          </thead>
          <tbody>
            {rectBounds.map((bound, idx) => (
              <tr key={idx}>
                <td>{ bound.x.toFixed(3) }</td>
                <td>{ bound.y.toFixed(3) }</td>
                <td>{ bound.width.toFixed(3) }</td>
                <td>{ bound.height.toFixed(3) }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <span className="title">3. The bounds data is turned into a Float32 RGBA GL buffer</span>
        <div className="gl-buffer">
          {Array(16).fill(0).map((v, idx) => {
            const px = rectBounds[idx]
            
            const r = px ? `rgb(${(255*px.x)|0}, 0, 0)` : 'lightgrey'
            const g = px ? `rgb(0, ${(255*px.y)|0}, 0)` : 'lightgrey'
            const b = px ? `rgb(0, 0, ${(255*px.width)|0})` : 'lightgrey'
            const a = px ? `rgb(${(255*px.height)|0}, ${(255*px.height)|0}, ${(255*px.height)|0})` : 'lightgrey'

            return (
              <div className="row" key={idx}>
                <div style={{ backgroundColor: r }} />
                <div style={{ backgroundColor: g }} />
                <div style={{ backgroundColor: b }} />
                <div style={{ backgroundColor: a }} />
              </div>
            )
          })}
        </div>
      </div>

      <div>
        <span className="title">4. The output texture of the <em>rectangles</em> fragment shader (can be used as input to any fragment shader)</span>
        <canvas ref={canvasRef} />
      </div>

    </StCont>
  )
}

export default Example