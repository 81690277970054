import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"


const Header = ({ onClick, title, link, linkTitle }) => (
  <header>
    <h1>{ title }</h1>
    <Link to={link} onClick={onClick}>{ linkTitle }</Link>
  </header>
)

export default Header