import React, { forwardRef, useEffect } from "react"
import styled from "styled-components"
import { useMounted } from "Utils/Hooks"


const StAppear = styled.p`
  animation: blink 0.4s linear 0s 10 alternate;
  font-variation-settings: 'wght' 900;

  @keyframes blink {
    from {
      color: white;
      font-variation-settings: 'wght' 300;
    }
    to {
      color: red;
      font-variation-settings: 'wght' 900;
    }
  }
`

const HiddenContent = (props) => {
  const [mounted, unmount] = useMounted()

  return (
    <>
      <StAppear mounted={mounted}>
        <em>Bravo !</em> you found a way to break your chains. This, is the reward. You can enjoy watching the differential growth of what you have just broken.
      </StAppear>

      <p>
        If there were no perturbations on this page, anybody would have seen all the content available. But by making it hard to access, those who went through the struggle to see it will probably be more attentive than they would have ever been otherwise.
      </p>

      <p>
        Everything is a balance between risks and rewards. 
      </p>

      <p>
        <strong><em>This whole series of experiment are hiding an enigma.</em></strong>
      </p>
    </>
  )
}

export default HiddenContent