import { Vector } from "matter-js"

class MouseTarget {
  constructor (x, y, friction = 0.008, randomness = 0.15) {
    this.pos = Vector.create(x, y)
    this.vel = Vector.create(0, 0)
    this.acc = Vector.create(0, 0)
    this.invFriction = 1. - friction
    this.randomness = randomness
  }

  follow (x, y) {
    let dir = Vector.create(x-this.pos.x, y-this.pos.y)
    dir = Vector.normalise(dir)
    let d = Vector.magnitude(dir)
    let M = Vector.mult(dir, 0.2/(d*d+0.00001))
    this.acc = Vector.add(this.acc, M)
  }

  update (dt) {
    let rand = Vector.create((Math.random()-.5) * this.randomness, (Math.random()-.5) * this.randomness)
    this.acc = Vector.add(this.acc, rand)
    this.vel = Vector.add(this.vel, this.acc)
    this.acc.x = 0
    this.acc.y = 0
    this.pos = Vector.add(this.pos, this.vel)
    this.vel = Vector.mult(this.vel, this.invFriction)
  }
}

export default MouseTarget