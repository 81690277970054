import Experiment from "Core/Experiment"
import Graph, { Edge } from "./Graph"
import SemanticExperimentComponent from "./Component"


const maxWidth = 4

class SemanticExperiment extends Experiment {
  isRunning = false
  mouseX = window.innerWidth * .5
  mouseY = window.innerHeight * .5

  targetNote = 0.5

  /** @type {Graph} */
  graph = null

  cvs = null
  /** @type {CanvasRenderingContext2D} */
  ctx = null

  hoveredNode = null

  constructor () {
    super()
    this.loop = this.loop.bind(this)
  }

  /**
   * 
   * @param {HTMLElement} element 
   * @param {HTMLElement} textElem 
   */
  init (element, textElem, canvas) {
    super.init(element)

    return new Promise((resolve) => {
      this.textElem = textElem
      this.cvs = canvas
      this.ctx = canvas.getContext('2d')

      // extract a list of all the noted words (as a list of HTMLElement)
      const nodes = textElem.querySelectorAll('span')

      // create semantic graph from the nodes
      console.time('graph-creation')
      this.graph = new Graph(nodes, 'data-note')
      console.timeEnd('graph-creation')
      console.log(this.graph)

      // add listeners to all the nodes for highlights
      for (const n of nodes) {
        n.addEventListener('mouseenter', this.onMouseEntersNode)
        n.addEventListener('mouseleave', this.onMouseLeavesNode)
      }

      // setup listeners
      document.addEventListener("mousemove", this.onMouseMoveDocument)
      document.addEventListener("mousedown", this.onMouseDownDocument)
      window.addEventListener("resize", this.onWindowResize)

      this.notifyOnLoadCallbacks(1)

      resolve()
    })
  }

  onMouseMoveDocument = (event) => {
    this.mouseX = event.clientX 
    this.mouseY = event.clientY
  }

  onMouseDownDocument = () => {
  }

  onMouseEntersNode = (event) => {
    this.hoveredNode = event.target
  }

  onMouseLeavesNode = () => {
    this.hoveredNode = null
  }

  onWindowResize = () => {
  }

  start () {
    super.start()
    this.isRunning = true
    this.loop()
  }

  stop () {
    super.stop()
    this.isRunning = false
  }

  loop () {
    if (this.isRunning) {
      requestAnimationFrame(this.loop)
      super.loop()  // compute the timers
      
      // 1. get visible blocks
      const visible = this.getVisibleBlocks()
      
      // 2. derive the edges which might be rendered
      const edges = this.graph.getEdgesFromNodes(visible)

      // 3. clear & render the edges
      this.ctx.fillStyle = 'white'
      this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height)
      this.renderEdges(edges)
    }
  }

  getVisibleBlocks () {
    const inside = []
    for (const elem of this.graph.nodes) {
      const bounding = elem.getBoundingClientRect()
      // most optimized way I found to know if element is in the window
      if (bounding.top + bounding.height > 0 && bounding.top < window.innerHeight) {
        inside.push(elem)
      }
    }
  
    return inside
  }

  /**
   * 
   * @param {Array.<Edge>} edges 
   */
  renderEdges = (edges) => {
    const ctx = this.ctx

    // todo: in getVisibleBlocks, keep the center to prevent calcu.
    // todo: animate in ?
    // todo: have target range instead of single value ?

    let i = 0
    let bd1, bd2, x1, y1, x2, y2, color, width
    for (const edge of edges) {
      // compute extremities positions
      bd1 = edge.node1.getBoundingClientRect()
      bd2 = edge.node2.getBoundingClientRect()
      x1 = bd1.x + bd1.width*.5
      x2 = bd2.x + bd2.width*.5
      y1 = bd1.y + bd1.height*.5
      y2 = bd2.y + bd2.height*.5

      // compute line width based on distance from target color
      width = 1 - (Math.abs(edge.value-this.targetNote) / 0.6)

      if (width > 0) {
        ctx.lineWidth = width * maxWidth
        color = (this.hoveredNode===edge.node1 || this.hoveredNode===edge.node2) ? edge.color.clone().setAlpha(1) : edge.color
        ctx.strokeStyle = color.toRgbString()
        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.stroke()
      }
    }
  }

  destroy () {
    return new Promise(resolve => {
      document.removeEventListener("mousemove", this.onMouseMoveDocument)
      document.removeEventListener("mousedown", this.onMouseDownDocument)
      window.removeEventListener("resize", this.onWindowResize)

      for (const n of this.graph.nodes) {
        n.removeEventListener('mouseenter', this.onMouseEntersNode)
        n.removeEventListener('mouseleave', this.onMouseLeavesNode)
      }

      resolve()
    })
  }
}

SemanticExperiment.link = "/semantic"
SemanticExperiment.title = "Highlighting semantic connections"
SemanticExperiment.description = "Proposing contextual connexions as a visual graph"
SemanticExperiment.component = SemanticExperimentComponent

export default SemanticExperiment