import React from "react";
import styled from "styled-components"
import { Link } from "react-router-dom"
import ExperimentComponent from "Core/ExperimentComponent"
import HomeExperiment from  './index'
import Movable from "./Movable"
import FakeWindow from "./FakeWindow"
import Data from "./Data"


const StCont = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;

  canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background: black !important;
  }

  .window {
    position: fixed;
    z-index: 200;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

    &.on-top {
      z-index: 400;
    }
  }

  .window.window-look {
    .title {
      height: 30px;
      background: white;
      border-bottom: 1px solid black;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: move;
      position: relative;
      padding-left: 20px;

      &::after, &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        top: 50%;
        background: black;
        transform: translateY(-50%);
      }

      &::after {
        height: 2px;
        right: 24px;
      }
    }

    .body {
      line-height: 0;
    }

    .body textarea { 
      padding: 50px;
      background: white;
      font-family: 'Fira Code';
      font-size: large;
      line-height: 30px;
    }
  }

  .win1 {
    top: 30px;
    left: 30px;
  }

  .win2 {
    top: 240px;
    left: 60px;
  }

  .message {
  }

  .button {
    position: fixed;
    left: 50%;
    bottom: 20px;
    background: white;

    .title {
      padding: 30px 0;
      width: 210px;
      background: transparent;
      position: absolute;
      top: 0;
      background: white;
      text-align: center;
      cursor: move;
      border-bottom: 2px solid black;
      box-sizing: content-box;
    }

    .hidden {
      width: 210px;
      min-height: 90px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      justify-content: center;
      padding-bottom: 30px;
      box-sizing: border-box;
      animation: blink 4s alternate-reverse .5s infinite;

      &:hover {
        animation: blink 0.5s alternate-reverse .1s infinite;
      }
    }
  }

  header {
    position: absolute;
    top: 40px;
    right: 40px;
    color: white;
    text-align: right;
    z-index: 10;

    & > * {
      padding: 20px 40px;
      margin: 0;
      background: black;
    }
  }

  a {
    color: #00ff00;
    text-decoration: none;
    font-variation-settings: 'wght' 300;
    transition: all .3s;

    &:hover {
      font-variation-settings: 'wght' 900;
    }
  }

  @keyframes blink {
    from {
      //color: black;
      font-variation-settings: 'wght' 900;
    }
    to {
      font-variation-settings: 'wght' 300;
      //color: white;
    }
  }

  .transitioner {
    position: fixed;
    z-index: 10000;
    opacity: 0;
    background: white;
    transition: all 0.6s;

    &.fade-in {
      opacity: 1;
    }

    &.animate {
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      background: black;
      transition: all 1.4s;
    }
  }
`

const StToggle = styled.label`
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;

  &:hover {
    opacity: 1;
  }

  input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`

class MaterializingExperimentComponent extends ExperimentComponent {
  /**
   * A list of the "FakeWindows" created from DOM children of this component
   * @type {Array.<FakeWindow>}
   */
  windows = []
  showTargets = false
  
  constructor (props) {
    super(props)

    this.containerRef = React.createRef()

    this.win1Ref = React.createRef()
    this.win2Ref = React.createRef()
    this.win3Ref = React.createRef()
    this.collider1Ref = React.createRef()
    this.collider2Ref = React.createRef()
    this.collider3Ref = React.createRef()
    this.transitionRef = React.createRef()
  }

  init (element) {
    return this.experiment.init(
      this.containerRef.current, 
      [
        this.win1Ref.current, 
        this.win2Ref.current, 
        this.win3Ref.current,
        this.collider1Ref.current,
        this.collider2Ref.current,
        this.collider3Ref.current,
      ]
    )
  }

  destroy () {
    for (const win of this.windows) {
      win.destroy()
    }
    return this.experiment.destroy()
  }

  componentDidMount () {
    this.windows.push(new FakeWindow(this.win1Ref.current))
    this.windows.push(new FakeWindow(this.win3Ref.current))
    this.windows.push(new FakeWindow(this.win2Ref.current, this.win2Ref.current.querySelector('.title')))
  }

  onTogglePoints = (event) => {
    this.experiment.showTargets = event.target.checked
  }

  startTransitionOut = (event) => {
    event.preventDefault()

    const elem = this.transitionRef.current

    // fit transitioner to the elem bounds
    const bounds = this.win2Ref.current.getBoundingClientRect()
    elem.style.left = bounds.left + 'px'
    elem.style.top = bounds.top + 'px'
    elem.style.right = (window.innerWidth - bounds.left - bounds.width) + 'px'
    elem.style.bottom = (window.innerHeight - bounds.top - bounds.height) + 'px'

    elem.ontransitionend = () => {
      elem.ontransitionend = () => {
        elem.ontransitionend = null
        this.props.onStop()
      }
      elem.classList.add('animate')
    }
    elem.classList.add('fade-in')
  }

  render () {
    return (
      <StCont ref={this.containerRef}>
        <StToggle htmlFor="toggle-points" ref={this.collider3Ref}>
          <input 
            id="toggle-points"
            name="toggle-points"
            type="checkbox"
            onChange={this.onTogglePoints}
          />
        </StToggle>

        <div className="window window-look win1" ref={this.win1Ref}>
          <div className="title">1.</div>
          <div className="body">
            <textarea 
              className="message"
              defaultValue={Data.text1}
            />
          </div>
        </div>

        <div className="window window-look win2" ref={this.win3Ref}>
          <div className="title">2.</div>
          <div className="body">
            <textarea 
              className="message"
              defaultValue={Data.text2}
            />
          </div>
        </div>

        <div className="button window" ref={this.win2Ref}>
          <div className="title">Explore</div>
          <Link to="/" className="hidden" onClick={this.startTransitionOut}>Summary</Link>
        </div>

        <header>
          <h1 ref={this.collider1Ref}>2. {HomeExperiment.title}</h1>
          <Link to="/" ref={this.collider2Ref} onClick={this.startTransitionOut}>summary</Link>
        </header>

        <div className="transitioner" ref={this.transitionRef} />
      </StCont>
    )
  }
}

export default MaterializingExperimentComponent