import React from "react"
import styled from "styled-components"


const StCont = styled.div`
  width: 60%;
  margin: auto;
  padding-bottom: 100px;

  p {
    line-height: normal;
    background: white;
    padding: 5px;
    margin-top: 20px !important;
  }

  p:nth-child(2n) {
    position: relative;
    left: 30px;
  }

  p:nth-child(2n+1) {
    position: relative;
    right: 30px;
  }

  h6 {
    margin-top: 100px;
    font-size: 2em;
  }
`

const FakeWebPage = () => {
  return (
    <StCont>
      <h6>A fake webpage !</h6>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ullamcorper morbi tincidunt ornare massa eget egestas purus. Risus nec feugiat in fermentum posuere urna nec tincidunt praesent. Ornare massa eget egestas purus viverra accumsan in nisl. Neque viverra justo nec ultrices dui sapien eget mi proin. Viverra justo nec ultrices dui sapien eget mi proin sed. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Nulla porttitor massa id neque aliquam vestibulum morbi blandit cursus. Fermentum et sollicitudin ac orci phasellus egestas tellus.
      </p>
      <p>
        Dui accumsan sit amet nulla facilisi morbi tempus iaculis. Sed adipiscing diam donec adipiscing tristique. Amet massa vitae tortor condimentum lacinia quis vel eros. At auctor urna nunc id cursus metus aliquam. Euismod elementum nisi quis eleifend quam adipiscing vitae proin. Et magnis dis parturient montes nascetur. Et netus et malesuada fames ac turpis egestas. Tempus urna et pharetra pharetra massa massa. Feugiat in fermentum posuere urna nec tincidunt. Maecenas pharetra convallis posuere morbi leo. Commodo viverra maecenas accumsan lacus vel facilisis volutpat. Vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Nulla pellentesque dignissim enim sit amet venenatis urna. Lorem sed risus ultricies tristique nulla. Accumsan in nisl nisi scelerisque eu ultrices vitae. Sed euismod nisi porta lorem. Scelerisque varius morbi enim nunc faucibus a pellentesque. Et ultrices neque ornare aenean euismod elementum nisi. Amet consectetur adipiscing elit duis tristique sollicitudin nibh.
      </p>
      <p>
        Faucibus vitae aliquet nec ullamcorper. Dictum varius duis at consectetur lorem donec massa sapien faucibus. Nisi quis eleifend quam adipiscing vitae. Nibh nisl condimentum id venenatis. Volutpat sed cras ornare arcu dui vivamus arcu felis. Natoque penatibus et magnis dis parturient. Ac felis donec et odio pellentesque diam. A iaculis at erat pellentesque adipiscing commodo. Accumsan lacus vel facilisis volutpat est velit. Velit 
        sed ullamcorper morbi tincidunt ornare massa eget egestas purus. Enim sit amet venenatis urna cursus.
      </p>
      <p>
        Nulla facilisi morbi tempus iaculis urna id volutpat lacus. Aliquam id diam maecenas ultricies mi eget. Eleifend donec pretium vulputate sapien nec. Elementum pulvinar etiam non quam lacus suspendisse. Odio ut sem nulla pharetra. Ultricies tristique nulla aliquet enim tortor at auctor. In fermentum et sollicitudin ac orci phasellus egestas tellus rutrum. Lacus viverra vitae congue eu consequat ac felis. Elementum curabitur vitae nunc sed velit dignissim sodales ut eu. Metus aliquam eleifend mi in nulla. Nascetur ridiculus mus mauris vitae. Viverra vitae congue eu consequat ac felis donec. Tristique magna sit amet purus gravida quis blandit.
      </p>
      <p>
        Maecenas ultricies mi eget mauris pharetra et ultrices neque. Non nisi est sit amet facilisis magna etiam tempor orci. Vulputate ut pharetra sit amet aliquam id diam maecenas ultricies. Porta nibh venenatis cras sed felis eget velit aliquet. Rhoncus dolor purus non enim praesent. Ante in nibh mauris cursus mattis molestie a iaculis. Sit amet purus gravida quis blandit turpis. Gravida arcu ac tortor dignissim. Sed risus ultricies tristique nulla aliquet enim tortor at. Metus aliquam eleifend mi in nulla posuere. Nisi scelerisque eu ultrices vitae auctor eu augue. Orci phasellus egestas tellus rutrum tellus. Neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing. Donec enim diam vulputate ut pharetra sit amet aliquam. Eget nulla facilisi etiam dignissim diam quis enim. At risus viverra adipiscing at in. Semper quis lectus nulla at volutpat diam ut venenatis. Viverra orci sagittis eu volutpat odio facilisis mauris sit. Gravida rutrum quisque non tellus orci ac auctor. Scelerisque varius morbi enim nunc faucibus a pellentesque.
      </p>
    </StCont>
  )
}

export default FakeWebPage