import React from "react";
import styled from "styled-components"
import ExperimentComponent from "Core/ExperimentComponent"
import Content from "./Content"
import HiddenContent from "./HiddenContent"
import PerturbationsExperiment from './index'
import { Link } from "react-router-dom"
import DodgeButton from "./DodgeButton"


const StCont = styled.div`
  color: white;

  button {
    position: fixed;
    top: 0;
    left: 0;
  }

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.isTransition ? 10000 : -100};
  }

  header {
    position: fixed;
    top: 75px;
    left: 75px;
    mix-blend-mode: difference;
  }

  main {
    width: 50%;
    position: absolute;
    top: 25vh;
    left: 50%;
    padding-left: 50px;
    max-width: 600px; 
    font-size: 15px;
    padding-bottom: 150px;
    mix-blend-mode: difference;

    p {
      margin-top: 100px;
    }
  }

  .anchor {
    position: fixed;
    top: 50%;
    right: calc(50% + 300px);
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: ${props => props.broken ? 'transparent' : 'red'};
    border-radius: 50%;
    transition: all .3s;
  }

  .anchor-target {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-right: 40px;
    transform: translateY(-50%);
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.broken ? 'transparent' : 'red'};
    transition: all .3s;
  }

  .mouse {
    position: fixed;
    z-index: 1000;
    width: 60px;
    height: 60px;
    background: white;
    pointer-events: none;
    border-radius: 50%;
    mix-blend-mode: difference;
  }

  .transitioner {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 100%;
    left: 0;
    background: black;
    transition: all 1s;
    pointer-events: none;

    &.out {
      bottom: 0;
    }
  }
`

class PerturbationsExperimentComponent extends ExperimentComponent {
  state = {
    broken: false,
    isTransition: false,
  }

  constructor (props) {
    super(props)

    this.canvasRef = React.createRef()
    this.anchorRef = React.createRef()
    this.movingAnchorRef = React.createRef()
    this.mouseRef = React.createRef()
    this.transitionerRef = React.createRef()
  }

  get canvas () {
    return this.canvasRef.current
  }

  init (element) {
    window.scrollTo(0, 0)
    this.experiment.onBreaking = this.onBreaking
    this.experiment.onTransitionOutEnd = this.onTransitionOutEnd
    return this.experiment.init(element, this.canvas, this.anchorRef.current, this.movingAnchorRef.current, this.mouseRef.current)
  }

  destroy () {
    return this.experiment.destroy()
  }

  onBreaking = () => {
    this.setState(st => ({
      ...st,
      broken: true,
    }))
  }

  onTransitionOutEnd = () => {
    // start the last transition
    document.body.style.overflow = "hidden"
    const T = this.transitionerRef.current
    T.classList.add('out')
    T.ontransitionend = () => {
      this.props.onStop()
      document.body.style.overflow = "visible"
    }
  }

  addPoint = () => {
    this.experiment.addPoint(40)
  }

  componentDidMount () {
  }

  startTransitionOut = (event) => {
    event.preventDefault()
    this.experiment.startTransitionOut()
    this.setState(st => ({
      ...st,
      isTransition: true,
    }))
  }

  render () {
    return (
      <StCont isTransition={this.state.isTransition} broken={this.state.broken}>
        <canvas ref={this.canvasRef} />

        <div className="anchor" ref={this.anchorRef} />

        <header>
          <h1>{ PerturbationsExperiment.title }</h1>
          <Link to="/" onClick={this.startTransitionOut}>summary</Link>
        </header>

        <main>
          <Content ref={this.movingAnchorRef} />
          {this.state.broken && <HiddenContent />}
        </main>

        {!this.state.broken && (
          <DodgeButton onClick={this.addPoint} canvas={this.canvasRef}>
            free yourself
          </DodgeButton>
        )}

        <div className="mouse" ref={this.mouseRef} />

        <div className="transitioner" ref={this.transitionerRef} />
      </StCont>
    )
  }
}

export default PerturbationsExperimentComponent