import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import DomToEnv from "Utils/DomToEnv"
import { useAnimationFrame } from "Utils/Hooks"
import FakeWebPage from "../FakeWebPage"


const StCont = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
  & > * {
    flex-basis: 50%;
    padding: 15px;
  }

  .title {
    margin-bottom: 15px;
    display: block;
    font-size: 0.9em;
    line-height: normal;
    max-width: 80%;
  }

  .window {
    width: 100%;
    padding-top: 66.6%;
    background: #eeeeee;
    position: relative;

    .window-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      border: 2px solid black;
      font-size: 5px;
    }
  }

  canvas {
    border: 2px solid black;
  }
`

const Example = () => {
  const container = useRef()
  const canvasRef = useRef()
  const [rectBounds, setRectBounds] = useState([])

  useEffect(() => {
    const cont = container.current
    const elems = cont.querySelectorAll('p')
    const domEnv = new DomToEnv(elems, cont)

    canvasRef.current.width = cont.clientWidth
    canvasRef.current.height = cont.clientHeight

    const onScroll = () => {
      const bounds = domEnv.getVisibleRects()
      setRectBounds(bounds)
      const ctx = canvasRef.current.getContext('2d')
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
      ctx.strokeStyle = "red"
      ctx.lineWidth = 2
      for (const b of bounds) {
        ctx.beginPath()
        ctx.rect(b.x, b.y, b.width, b.height)
        ctx.stroke()
      }
    }

    onScroll()
    cont.addEventListener('scroll', onScroll)

    return () => {
      cont.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <StCont>

      <div>
        <span className="title">1. The paragraphs (white background) are marked as <em>interractive</em></span>

        <div className="window">
          <div className="window-wrapper" ref={container}>
            <FakeWebPage />
          </div>
        </div>
      </div>

      <div>
        <span className="title">2. The bounding rectangles of the visible marked elements are computed</span>
        <table>
          <thead>
            <tr>
              <td>x</td>
              <td>y</td>
              <td>width</td>
              <td>height</td>
            </tr>
          </thead>
          <tbody>
            {rectBounds.map((bound, idx) => (
              <tr key={idx}>
                <td>{ bound.x | 0 }</td>
                <td>{ bound.y | 0 }</td>
                <td>{ bound.width | 0 }</td>
                <td>{ bound.height | 0 }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <span className="title">3. The rectangles can be used in a virtual environment. In this case, they are simply drawn on a canvas</span>
        <canvas ref={canvasRef} />
      </div>

    </StCont>
  )
}

export default Example