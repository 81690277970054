import React from "react";
import styled from "styled-components";
import Experiment from "Core/Experiment"


/**
 * The ExperimentComponent can be seen as a View which acts as a bridge between the view of the main app and the logic of the
 * experiment. Basically, if an Experiment has a static property pointing to a children of this class, the ExperimentContainer
 * won't directly interact with the Experiment instance but rather with this in-between component. It can be useful if an
 * experiment wants to have access to some DOM elements, that those DOM elements are handled by a children of this class
 */
class ExperimentComponent extends React.Component {
  constructor (props) {
    super(props)
    
    /**
     * @type {Experiment}
     */
    this.experiment = props.experiment
  }

  init (element) {
    return this.experiment.init(element)
  }

  start () {
    return this.experiment.start()
  }

  stop () {
    return this.experiment.stop()
  }

  destroy () {
    return this.experiment.destroy()
  }
}

export default ExperimentComponent