import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Vector2, Vector3 } from "three"
import { useMouse, useAnimationFrame } from "Utils/Hooks"


function isLeft (a, b, c) {
  return ((b.x - a.x)*(c.y - a.y) - (b.y - a.y)*(c.x - a.x)) > 0;
}

const StButton = styled.button`
  padding: 10px 15px;
  font-family: 'Fira Code';
  cursor: pointer;
  transition: transform .3s, background-color .3s;
  font-weight: bold;
  background: white;
  border: none;
  outline: none;

  &:hover {
    transform: scale(1.1);
  }
  
  &:active {
    transform: scale(1.2);
    background: red;
  }
`

const DodgeButton = ({ onClick, children, canvas }) => {
  const button = useRef()
  const centerX = useRef()
  const centerY = useRef()
  const stuck = useRef(false)

  // const far = useRef(new Vector2(0,0))

  useEffect(() => {
    const C = new Vector2(
      window.innerWidth * .3,
      window.innerHeight * .5,
    )
    const R = 300
    centerX.current = C.x-R
    centerY.current = C.y
    button.current.style.left = centerX.current-button.current.clientWidth*.5 + 'px'
    button.current.style.top = centerY.current-button.current.clientHeight*.5 + 'px'
  }, [])

  const onMouseMove = (x, y, event) => {
    if (!stuck.current) {
      let dir

      const bounds = button.current.getBoundingClientRect()
      const center = new Vector2(
        centerX.current,
        centerY.current,
      )

      const M = new Vector2(x, y)

      // rule: the button moves to the fartest point from the mouse on a circle 
      const C = new Vector2(
        window.innerWidth * .3,
        window.innerHeight * .5,
      )
      const R = 300
      
      // find the farther point from the mouse on the circle
      // 1. vector mouse -> cicle center
      const MC = C.clone().sub(M)
      // 2. get its direction
      dir = MC.clone().normalize()
      // 3. add dir*radius to find the fartest point
      const P = C.clone().add(dir.multiplyScalar(R))

      // far.current.copy(P)

      // find which direction which needs to be taken to reach such point
      dir = isLeft(C, center, P) ? 1 : -1
      
      // mouse velocity
      const Vel = (new Vector2(event.movementX, event.movementY)).length()

      // given the direction and current angle, computes new position
      const Ccenter = center.clone().sub(C)
      const nA = Ccenter.angle() + dir * Vel*.0035
      const nPos = new Vector2(
        Math.cos(nA) * R + C.x,
        Math.sin(nA) * R + C.y,
      )

      // set element position based on target point
      centerX.current = nPos.x
      centerY.current = nPos.y
      button.current.style.left = centerX.current-button.current.clientWidth*.5 + 'px'
      button.current.style.top = centerY.current-button.current.clientHeight*.5 + 'px'
    }
  }

  const [mouseX, mouseY] = useMouse(0, 0, onMouseMove)

  const onOver = () => {
    stuck.current = true
  }

  const onOut = () => {
    stuck.current = false
  }

  // useAnimationFrame(() => {
  //   const cvs = canvas.current
  //   /** @type {CanvasRenderingContext2D} */
  //   const ctx = cvs && cvs.getContext('2d')
  //   if (!ctx) return

  //   const C = new Vector2(
  //     window.innerWidth * .3,
  //     window.innerHeight * .5,
  //   )
  //   const R = 300

  //   ctx.clearRect(0, 0, cvs.width, cvs.height)
    
  //   ctx.strokeStyle = "red"
  //   ctx.beginPath()
  //   ctx.arc(C.x, C.y, R, 0, 6.29)
  //   ctx.stroke()

  //   ctx.fillStyle = "#00ff00"
  //   ctx.fillRect(far.current.x - 4, far.current.y - 4, 8, 8)
  // })

  return (
    <StButton onClick={onClick} ref={button} onMouseEnter={onOver} onMouseLeave={onOut}>
      { children }
    </StButton>
  )
}

export default DodgeButton