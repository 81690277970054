import * as THREE from "three"
import QuadTarget from "./QuadTarget"
import QuadVS from "./shaders/quad.vert.glsl"
import BlocksFS from "./shaders/blocks.frag.glsl"
import DomToEnv from "Utils/DomToEnv"


// the maximum number of elements supported by the utility
const MAX_ELEMENTS = 16

/**
 * This utility can be used to render visible rectangular DOM elements to a GL Texture
 * It needs to be initialized with an array of the DOM elements it needs to track, and ensures than a draw call will ouput
 * a texture which is the same size as its internal width and height values
 */
class DOMtoGLTexture {
  width = 512
  height = 512

  /**
   * 
   * @param {boolean} autoUpdate if set to true, the texture will be updated on window scroll, otherwise the method "update"
   *                             should be called manually when it fits the need
   */
  constructor (autoUpdate) {
    this.autoUpdate = autoUpdate
  }

  /**
   * Creates the buffers and the shader programs to handle the drawing
   * @param {THREE.WebGLRenderer} renderer
   * @param {Array.<HTMLElement>} elements 
   */
  init (renderer, elements, width, height, container = null) {
    this.renderer = renderer
    this.elements = elements
    this.width = width
    this.height = height

    // instanciate the utility to get the visible blocks
    this.domEnv = new DomToEnv(elements, container)

    // create the buffers in which the data of the blocks will be stored
    this.rectangles = new Float32Array(MAX_ELEMENTS * 4)
    this.rectanglesTexture = new THREE.DataTexture(this.rectangles, MAX_ELEMENTS, 1, THREE.RGBAFormat, THREE.FloatType)

    // create the shader program
    this.material = new THREE.RawShaderMaterial({
      uniforms: {
        uResolution: { value: new THREE.Vector2(width, height) },
        uBlocks: { value: this.rectanglesTexture },
        uBlockCount: { value: 0 },
        uOpacity: { value: 1 },
      },
      vertexShader: QuadVS,
      fragmentShader: BlocksFS,
    })

    this.target = new QuadTarget(this.width, this.height, this.material)
    this.update()

    if (this.autoUpdate)
      window.addEventListener("scroll", this.onScroll)
  }

  destroy () {
    if (this.autoUpdate)
      window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    this.update()
  }

  hideBlocks () {
    this.material.uniforms['uOpacity'].value = 0
  }

  update () {
    // this.material.uniforms.window.value = Globals.backgroundSize
    // 1. compute the visible blocks bounding rect in unit coordinates
    const blocks = this.getVisibleBlocks()
    this.updateTextureFromBlocks(blocks)
    this.material.uniforms.uBlockCount.value = blocks.length

    // 2. render those blocks to the texture
	  this.target.render(this.renderer, 0)
  }

  get texture () {
    return this.target.texture
  }

  getVisibleBlocks () {
    // pixel coordinates
    let inside = this.domEnv.getVisibleRects()

    // limit the size to MAX
    inside = inside.slice(0, MAX_ELEMENTS)

    // into unit coordinates
    const contBds = this.domEnv.getContainerBounds()
    inside.forEach(rect => {
      rect.x/= contBds.width
      rect.y/= contBds.height
      rect.width/= contBds.width
      rect.height/= contBds.height
    })

    return inside
  }

  updateTextureFromBlocks (blocks) {
    for (let i = 0; i < blocks.length; i++) {
      const idx = i * 4;
      this.rectangles[idx] = blocks[i].x
      this.rectangles[idx+1] = 1 - (blocks[i].y+blocks[i].height)
      this.rectangles[idx+2] = blocks[i].width
      this.rectangles[idx+3] = blocks[i].height
    }
    this.rectanglesTexture.needsUpdate = true
  }
}

export default DOMtoGLTexture