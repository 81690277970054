import Gradient from "tinygradient"


// from Range.js : rgba(255,0,0,1) 0%, rgba(150,150,150,1) 43%, rgba(150,150,150,1) 57%, rgba(0,255,0,1) 100%)
export const ramp = Gradient([
  { color: 'rgba(255,0,0,1)', pos: 0 },
  { color: 'rgba(150,150,150,1)', pos: 0.43 },
  { color: 'rgba(150,150,150,1)', pos: 0.57 },
  { color: 'rgba(0,255,0,1)', pos: 1 },
])


export class Edge {
  /**
   * 
   * @param {HTMLElement} node1 
   * @param {HTMLElement} node2 
   * @param {number} weight 
   * @param {number} value 
   */
  constructor (node1, node2, weight, value) {
    this.node1 = node1
    this.node2 = node2
    this.weight = weight
    this.value = value
    this.color = ramp.rgbAt(value)
    this.color.setAlpha(weight*.3)
  }

  exists (node1, node2) {
    return (this.node1 === node1 || this.node2 === node1) && (this.node1 === node2 || this.node2 === node2) 
  }
}

class SemanticGraph {
  /**
   * @type {Array.<HTMLElement>}
   */
  nodes = []
  idCount = 0

  // an array of the notations, storing is go fast go brrr
  notes = []

  // max distance of score for 2 nodes to be linked
  maxD = 0.3

  /**
   * @type {Array.<Edge>}
   */
  edges = []

  /**
   * Given a node ID as key, stores its links
   * This is for optimisation in the main loop of index.js
   */
  nodesEdges = new Map()

  /**
   * 
   * @param {Array.<HTMLElement>} nodes 
   */
  constructor (nodes, scoreAttr = 'data-note') {
    this.nodes = nodes

    // store their notations
    for (const node of nodes) {
      this.notes.push(parseFloat(node.getAttribute(scoreAttr)))
    }

    this.createFromNodes()
  }

  createFromNodes () {
    // each node should be linked to any node in distance range
    let n1, n2, s1, s2, d, weight, value, found
    for (let i = 0; i < this.nodes.length; i++) {
      n1 = this.nodes[i]
      s1 = this.notes[i]
      for (let j = 0; j < this.nodes.length; j++) {
        if (i === j) continue
        n2 = this.nodes[j]
        s2 = this.notes[j]

        // compute the distance between their score
        d = Math.abs(s2 - s1)

        // if distance is lower than threshold, and link doesn't exist, add a new link
        if (d >= this.maxD) continue
        weight = 1 - (d/this.maxD)
        value = (s1+s2) * .5
        found = false
        for (const e of this.edges) {
          if (e.exists(n1, n2)) {
            found = true
            break
          }
        }
        if (found) continue
        // link can safely be added to our graph
        this.addEdge(n1, n2, weight, value)
      }
    }
  }

  /**
   * 
   * @param {HTMLElement} n1 
   * @param {HTMLElement} n2 
   * @param {number} weight 
   * @param {number} value 
   */
  addEdge (n1, n2, weight, value) {
    const edge = new Edge(n1, n2, weight, value)
    this.edges.push(edge)

    // if a node doesn't have an ID, one needs to be generated automatically, and an entry in the map can be created
    if (!n1.id) {
      n1.id = 'node-auto-' + (++this.idCount)
      this.nodesEdges[n1.id] = []
    } 
    if (!n2.id) {
      n2.id = 'node-auto-' + (++this.idCount)
      this.nodesEdges[n2.id] = []
    }

    // the edge can be added to the maps
    this.nodesEdges[n1.id].push(edge)
    this.nodesEdges[n2.id].push(edge)
  }

  /**
   * Given an array of nodes, returns any potential edges between those, as a curated list (no duplicate)
   * @param {Array.<HTMLElement>} nodes 
   */
  getEdgesFromNodes (nodes) {
    const edges = []
    for (const n of nodes) {
      if (n.id && this.nodesEdges[n.id]) {
        // add all the edges to the list, if not already
        for (const e of this.nodesEdges[n.id]) {
          if (!edges.includes(e) && ( (e.node1 === n && nodes.includes(e.node2)) || (e.node2 === n && nodes.includes(e.node1)) )) {
            edges.push(e)
          }
        }
      }
    }
    return edges
  }
}

export default SemanticGraph