// the margins next to the window borders the FakeWindow cannot cross
const MARGINS = 20;

export default class FakeWindow {
  constructor (elem, boundingElem) {
    this.elem = elem
    this.boundingElem = boundingElem ? boundingElem : this.elem
    this.title = this.elem.querySelector('.title')
    this.elStartPos = { x: 0, y: 0 }
    this.mouseStartPos = { x: 0, y: 0 }
    this.isMouseDown = false

    this.elem.addEventListener("mousedown", this.elemMouseDown)
    this.title.addEventListener("mousedown", this.start)
    window.addEventListener("mousemove", this.mouseMove)
    window.addEventListener("mouseup", this.stop)
  }

  elemMouseDown = () => {
    // put the active window on top
    const windows = document.querySelectorAll('.window')
    for (const w of windows) {
      w.classList.remove('on-top')
    }
    this.elem.classList.add('on-top')
  }

  mouseMove = (evt) => {
    if (this.isMouseDown) {
      // compute the new position
      const bounds = this.boundingElem.getBoundingClientRect()
      let posX = bounds.left + evt.movementX
      let posY = bounds.top + evt.movementY
      
      // a check is performed to prevent the window from going out
      if (posX < MARGINS || posX + bounds.width > window.innerWidth - MARGINS) posX = bounds.left
      if (posY < MARGINS || posY + bounds.height > window.innerHeight - MARGINS) posY = bounds.top

      this.elem.style.left = posX + 'px'
      this.elem.style.top = posY + 'px'
      // this.elem.style.bottom = 'auto'
      // this.elem.style.right = 'auto'
    }
  }

  start = (evt) => {
    const bounds = this.elem.getBoundingClientRect()

    this.mouseStartPos.x = evt.pageX
    this.mouseStartPos.y = evt.pageY
    this.elStartPos.x = bounds.left
    this.elStartPos.y = bounds.top
    this.isMouseDown = true
    document.body.classList.add('move')
  }
  
  stop = () => {
    this.isMouseDown = false
    document.body.classList.remove('move')
  }

  destroy () {
    this.stop()
    this.elem.removeEventListener("mousedown", this.elemMouseDown)
    this.title.removeEventListener("mousedown", this.start)
    window.removeEventListener("mousemove", this.mouseMove)
    window.removeEventListener("mouseup", this.stop)
  }
}