import './App.css'
import Root from "Containers/Root"

function App() {
  return (
    <Root />
  )
}

export default App;
