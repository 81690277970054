import React from "react"
import styled from "styled-components"


const Introduction = () => {
  return (
    <>
      <p>
        This experiment explores the addition of a representation of semantic connections between the words of a text. Some of the words were given notation based on their emotive connotation going from 0 (bad) to 1 (good). Please excuse how naïve the choices might be, I tend to be more fluent with numbers than I am with words. Words will be more or less linked to others depending on the distance between their notations. The more they are linked, the more their link will be opaque. To each connexion made will be associated a notation, the average of the word notations on both extremities. The slider controls the “target” notation, which in turns controls which links should be displayed. Links are displayed wider the closer their notation is from the value of the slider. 
      </p>
      <p>
        This experiment demonstrates how easy a clear visual can bring useful insight to a text. In this case, choosing to link the words based on their <em>emotionnal value</em> is fairly simple, but we could imagine a display of more interesting data. The extract I chose is from <strong>Les thanatonautes</strong>, <em>Bernard Werber</em>.
      </p>
    </>
  )
}

export default Introduction