import React, { useRef, useEffect } from "react"
import styled from "styled-components"

import Header from "./Header"
import Transitioner from "Components/Transitioner"
import { withRouter } from "react-router-dom"


const StCont = styled.div`
  width: 100%;
  min-height: 100vh;
  background: white;

  header {
    padding-top: 75px;
    margin-left: 75px;
  }

  main {
    width: 800px;
    margin: 300px auto;
    padding-bottom: 300px;

    nav {

    }

    p, ol, ul, .example {
      margin-top: 50px;
    }

    ul, ol {
      margin-left: 25px;
    }

    li {
      margin-top: 15px;
    }

    h2 {
      margin-top: 70px;
    }

    a {
      color: blue;
      font-weight: 600;
      text-decoration: none;
      font-variation-settings: 'wght' 600;
      transition: all .3s;

      &:hover {
        color: #00ff00;
        font-variation-settings: 'wght' 900;
      }
    }

    .example {
      margin-left: -100px;
      margin-right: -100px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      
      thead {
        font-weight: bold;
        background: rgba(0, 0, 0, 0.1);
      }
      
      td {
        border: 1px solid rgba(0, 0, 0, 0.5);
        padding: 4px;
      }
    }
  }
`

const Page = ({ title, history, children }) => {
  const transRef = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onHeaderClick = (event) => {
    event.preventDefault()
    const transi = transRef.current
    transi(1100).then(() => {
      history.push('/')
    })
  }

  return (
    <StCont>

      <Header
        title={title}
        link="/"
        linkTitle="summary"
        onClick={onHeaderClick}
      />

      <main>
        { children }
      </main>

      <Transitioner ref={transRef} />

    </StCont>
  )
}

export default withRouter(Page)