import React from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"

import Experiment from "Core/Experiment"

import ExperimentLoader from "Components/ExperimentLoader"



/**
 * The ExperimentContainer component is responsible for providing a DOM node with which an experiment can interact. It is
 * also responsible for handling the instanciation, initialisation, start and destruction of such experiment 
 */
class ExperimentContainer extends React.Component {
  hasComponent = false
  signalsTarget = null

  state = {
    loading: 0,       // a loading progress value € [0; 1]
  }

  constructor (props) {
    super(props)

    const ExperimentClass = props.experiment
    /**
     * @type {Experiment}
     */
    this.experiment = new ExperimentClass()

    // checks if there is a component attached to experiment, and if so all the signals will be sent to it
    this.hasComponent = ExperimentClass.component || false

    this.container = React.createRef()
    this.expComponent = React.createRef()
  }

  componentDidMount () {
    this.experiment.registerOnLoadCallback(this.onExperimentLoad)
    this.signalsTarget = this.hasComponent ? this.expComponent.current : this.experiment

    this.signalsTarget.init(this.container.current)
      .then(() => {
        this.signalsTarget.start()
      })
  }

  onExperimentLoad = (progress) => {
    this.setState((state) => ({
      ...state,
      loading: progress,
    }))
  }

  onExperimentStop = () => {
    // stoping is synchronous, it is expected to be very fast
    this.signalsTarget.stop()

    // freeing the experiment resources
    this.signalsTarget.destroy()
      .then(() => {
        this.props.history.push('/home')
      })
  }

  render () {
    const { loading } = this.state
    const ExpComponent = this.hasComponent

    return (
      <>
        {ExpComponent && (
          <ExpComponent 
            ref={this.expComponent}
            experiment={this.experiment}
            onStop={this.onExperimentStop}
          />
        )}

        <ExperimentLoader
          progress={loading}
        />
      </>
    )
  }
}

export default withRouter(ExperimentContainer)