import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import { PagesData } from "./Pages.js"
import MountingEffect from "./MountingEffect"


const StNav = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  a {
    padding: 5px 0;
    padding-left: 5px;
    color: white;
    font-variation-settings: 'wght' 300;
    transition: .3s;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 3px;
      right: 0;
      width: 0;
      height: 1px;
      background: white;
      opacity: 0.2;
      transition: .5s linear .5s;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 0;
      height: 1px;
      background: white;
      opacity: 0.2;
      transition: .5s;
      transform-origin: left bottom;
      transform: rotate(225deg);
    }

    &.active {
      font-variation-settings: 'wght' 900;
      //padding-left: 7px;

      &::after {
        width: 100%;
        transition: .5s;
      }

      &::before {
        width: 100px;
        transition: .5s linear .5s;
      }
    }

    &:hover {
      font-variation-settings: 'wght' 900;
    }
  }
`

const Nav = ({ history, match, location, onNavigate, }) => {
  const path = match.path
  const pathname = location.pathname

  const isActive = (route) => pathname.indexOf(route) !== -1

  const navigate = (event, path) => {
    event.preventDefault()
    if (path !== pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      onNavigate(path)
    }
  }

  return (
    <StNav>
      {PagesData.map((page, idx) => (
        <Link 
          key={idx} 
          to={`${path}${page.path}`} 
          className={isActive(page.path) ? 'active' : ''}
          onClick={(evt) => navigate(evt, `${path}${page.path}`)}
        >
          { page.nav }
        </Link>
      ))}
      <MountingEffect />
    </StNav>
  )
}

export default withRouter(Nav)