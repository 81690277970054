import { useEffect, useState, useRef } from "react"

/**
 * @returns {boolean} true if component did mount false otherwise
 */
export function useMounted () {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 0);
  }, [])
  const unmount = (duration) => new Promise((resolve) => {
    setMounted(false)
    setTimeout(() => {
      resolve()
    }, duration)
  })
  return [ mounted, unmount ]
}

export function useMouse (x = 0, y = 0, callback = null) {
  const mouseX = useRef(x)
  const mouseY = useRef(y)

  useEffect(() => {
    const onMove = (event) => {
      mouseX.current = event.clientX
      mouseY.current = event.clientY
      callback && callback(mouseX.current, mouseY.current, event)
    }
    document.addEventListener('mousemove', onMove)

    return () => {
      document.removeEventListener('mousemove', onMove)
    }
  }, [])

  return [ mouseX, mouseY ]
} 

export const useAnimationFrame = (callback) => {
  const timer = useRef(0)
  const requestRef = useRef()
  const previousTimeRef = useRef()
  
  const animate = time => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = Math.min(32, time - previousTimeRef.current)
      timer.current+= deltaTime
      callback(deltaTime, timer.current)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])
}

export const useInScreen = (reference) => {
  const inScreen = useRef(false)

  useEffect(() => {
    const onIntersection = (entries) => {
      const entry = entries[0]
      inScreen.current = entry.isIntersecting
    }
    let observer = new IntersectionObserver(onIntersection)
    observer.observe(reference.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return inScreen
}

/**
 * Sets up a event listener on window resize which will call the callback upon firing. The event listener gets removed when
 * component unmounts.
 * @param {Function} callback
 * @param {?boolean} triggerOnInit [false] if set to true, the callback will be called once when component has mounted 
 */
export const useWindowResize = (callback, triggerOnInit = false) => {
  useEffect(() => {
    if (triggerOnInit) callback()
    window.addEventListener("resize", callback)
    return () => {
      window.removeEventListener("resize", callback)
    }
  }, [])
}