import React from "react";
import styled from "styled-components"
import ExperimentComponent from "Core/ExperimentComponent"
import ArtisticExpression from "./index"
import Data from "./Data"


const StCont = styled.div`
  width: 100%;
  min-height: 100vh;
  background: white;

  canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  header {
    position: absolute;
    top: 50px;
    right: 50px;
    background: black;
    padding: 20px;
    color: white;
    line-height: 45px;
    text-align: right;

    a {
      color: #00ff00;
      text-decoration: none;
      transition: all .3s;
      font-variation-settings: 'wght' 300;

      &:hover {
        font-variation-settings: 'wght' 900;
      }
    }
  }

  .content {
    margin-top: 400px;
    padding-bottom: 400px;
    width: 100%;
    position: absolute;
    display: grid;
    grid-template-columns: repeat(2, 37%);
    grid-template-rows: auto;
    grid-row-gap: 100px;
    grid-column-gap: 0;
    justify-content: center;

    article {
      background-color: black;
      color: white;
      padding: 20px;
      border-radius: 4px;
    }
  }

  .fade-out {
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
`

class ArtisticExpressionComponent extends ExperimentComponent {
  constructor (props) {
    super(props)

    this.containerRef = React.createRef()
    this.canvasRef = React.createRef()
  }

  init (element) {
    window.addEventListener("resize", this.onWindowResize)
    this.fitCanvasToWindow()

    // find thee feed & kill elements
    const f = this.containerRef.current.querySelectorAll(".feed")
    const k = this.containerRef.current.querySelectorAll(".kill")

    return this.experiment.init(element, this.canvasRef.current, f, k)
  }

  destroy () {
    window.removeEventListener("resize", this.onWindowResize)
    return this.experiment.destroy()
  }

  fitCanvasToWindow = () => {
    const cvs = this.canvasRef.current
    cvs.width = window.innerWidth
    cvs.height = window.innerHeight
  }

  onWindowResize = () => {
    this.fitCanvasToWindow()
    this.experiment.onWindowResize(window.innerWidth, window.innerHeight)
  }

  componentDidMount () {
  }

  startTransitionOut = (event) => {
    event.preventDefault()
    
    // 1. transition out the contents
    const targets = this.containerRef.current.querySelectorAll('.feed > *, .kill > *')
    targets[targets.length-1].ontransitionend = () => {
      // 2. start growth
      this.experiment.forcedKR = 0
      this.experiment.hideBlocks()

      let queue = 2
      const onAnimationEnd = () => {
        queue--
        if (queue === 0) {
          setTimeout(() => {
            this.props.onStop()
          }, 300)
        }
      }

      // 3. turn the lights off
      this.experiment.setLightsOff().then(onAnimationEnd)

      // 4. analyze transition & 
      this.experiment.transitionEndAnalyzer().then(onAnimationEnd)
    }
    targets.forEach(target => target.classList.add('fade-out'))
  }

  render () {
    return (
      <StCont ref={this.containerRef}>
        <canvas ref={this.canvasRef} />

        <header className="feed">
          <h1>{ ArtisticExpression.title }</h1>
          <a href="/" onClick={this.startTransitionOut}>summary</a>
        </header>

        <main className="content">
          {Data.articles.map((art, idx) => (
            <React.Fragment key={idx}>
              <article className={art.type}>
                <span dangerouslySetInnerHTML={{ __html: art.content }} />
              </article>
              {(idx%2===0) ? <><div/><div/></> : null}
            </React.Fragment>
          ))}
        </main>
      </StCont>
    )
  }
}

export default ArtisticExpressionComponent