import Experiment from "Core/Experiment"
import { Vector2 } from "three"
import PerturbationsExperimentComponent from "./Component"
import Node from "./Physics/Node"
import Easings from "@creenv/easings"


const pointsNb = 8
const growthDelay = 400
const transitionOutDuration = 1500

const fVar = (x) => Math.cos(x) * (Math.sin(x*2)+1) * Math.sin(x * .7) + 0.3


class PerturbationsExperiment extends Experiment {
  isRunning = false
  mouseX = -100
  mouseY = -100
  mouseRad = 30

  /**
   * @type {Array.<Node>}
   */
  nodes = []

  // how strong is the string pulling the content ?
  pullStrength = 0

  // event for breaking
  onBreaking = null
  onTransitionOutEnd = null
  lastGrowth = 0

  execTimeHist = Array(60).fill(0)

  // sim forces
  linkForce = 1
  repForce = 4000
  repRangeSq = 22*22

  /**
   * @type {CanvasRenderingContext2D}
   */
  ctx = null
  cvs = null

  // simulation states
  broken = false
  alive = false
  transitionOut = false
  transitionOutEnded = false

  // transition out data
  initialPositions = null
  targetPositions = null

  constructor () {
    super()
    this.loop = this.loop.bind(this)
  }

  /**
   * 
   * @param {HTMLElement} element 
   */
  init (element, canvas, anchor, movingAnchor, mouseElem) {
    super.init(element)

    return new Promise((resolve) => {
      this.anchor = anchor
      this.movingAnchor = movingAnchor
      this.mouseElem = mouseElem

      this.notifyOnLoadCallbacks(0.2)

      this.cvs = canvas
      this.ctx = canvas.getContext('2d')
      this.autoFitCanvas()

      // create the nodes & their links
      for (let i = 0; i < pointsNb; i++) {
        this.nodes[i] = new Node(300 + (i/pointsNb) * 400, 500, i === 0 || i === pointsNb-1)
      }
      this.notifyOnLoadCallbacks(0.7)

      for (let i = 1; i < pointsNb-1; i++) {
        this.nodes[i].addEdge(this.nodes[i-1])
        this.nodes[i].addEdge(this.nodes[i+1])
      }
      this.nodes[0].addEdge(this.nodes[1])
      this.nodes[pointsNb-1].addEdge(this.nodes[pointsNb-2])

      this.notifyOnLoadCallbacks(0.9)

      // setup listeners
      document.addEventListener("mousemove", this.onMouseMoveDocument)
      document.addEventListener("mousedown", this.onMouseDownDocument)
      window.addEventListener("resize", this.onWindowResize)

      this.notifyOnLoadCallbacks(1)

      resolve()
    })
  }

  onMouseMoveDocument = (event) => {
    this.mouseX = event.clientX 
    this.mouseY = event.clientY
  }

  onMouseDownDocument = () => {
  }

  onWindowResize = () => {
    this.autoFitCanvas()
  }

  autoFitCanvas = () => {
    this.cvs.width = window.innerWidth
    this.cvs.height = window.innerHeight
  }

  start () {
    super.start()
    this.isRunning = true
    this.loop()
  }

  stop () {
    super.stop()
    this.isRunning = false
  }

  /**
   * Given the 1d coordinate of a point on a line, 
   * @param {number} d the x-coordinate of the point on the line
   * @param {number} width the width of the rectangle
   * @param {number} height the height of the rectangle
   */
  mapLineOnRectSide = (d, width, height) => {
    let x, y, side

    // left side
    if (d > width*2+height) {
      side = "left"
      x = 0
      y = height - (d-(width*2+height))
    }
    // bottom
    else if (d > width+height) {
      side = "bottom"
      x = width - (d - (width+height))
      y = height
    }
    // rigth
    else if (d > width) {
      side = "right"
      x = width
      y = d - width
    }
    // top 
    else {
      side = "top"
      x = d
      y = 0
    }

    return new Vector2(x, y)
  }

  /**
   * parses the graph and returns it in the right order
   */
  getOrderedNodes = () => {
    let ordered = []
    const parseGraph = (node) => {
      if (ordered.includes(node)) {
        return
      }
      else {
        ordered.push(node)
        for (const edge of node.edges) {
          if (!ordered.includes(edge)) {
            parseGraph(edge)
            return
          }
        }
      }
    }
    parseGraph(this.nodes[0])

    return ordered
  }

  startTransitionOut = () => {
    if (!this.broken) {
      this.breakChain()
    }
    this.alive = true
    this.transitionOut = this.elapsed

    const W = window.innerWidth
    const H = window.innerHeight

    // we store each node position and compute each node end position
    this.initialPositions = this.nodes.map(node => node.pos.clone())

    // compute the perimeter and create a distribution of points at equidistance on the perimeter
    const peri = W*2 + H*2
    const L = peri / this.nodes.length
    this.targetPositions = []
    for (let i = 0; i < this.nodes.length; i++) {
      this.targetPositions[i] = this.mapLineOnRectSide(i*L, W, H)
    }

    // 2nd pass to find the closest point to each corner and fix it
    for (let i = 0; i < 4; i++) {
      const P = new Vector2(
        (i%2) * W,
        ((i/2)|0) * H,
      )
      let closestD = W*W+1
      let closest = null
      for (const target of this.targetPositions) {
        let D = target.clone().sub(P).lengthSq()
        if (D < closestD) {
          closestD = D
          closest = target
        }
      }
      closest.copy(P)
    }
  }

  addPoint = (d = 0, index = undefined) => {
    const idx = typeof index === 'undefined' ? (this.nodes.length*.5) | 0 : index
    const n = new Node(
      (this.nodes[idx-1].pos.x + this.nodes[idx].pos.x) * .5,
      (this.nodes[idx-1].pos.y + this.nodes[idx].pos.y) * .5 + d,
    )
    this.insertNodeAt(n, idx)
  }

  insertNodeAt = (node, idx) => {
    this.nodes[idx-1].edges.pop()
    this.nodes[idx].edges.shift()
    node.addEdge(this.nodes[idx-1])
    node.addEdge(this.nodes[idx])
    this.nodes[idx-1].addEdge(node)
    this.nodes[idx].edges.unshift(node)
    this.nodes.splice(idx, 0, node)
  }

  updateAnchors = () => {
    // fix node 1
    const bnds1 = this.anchor.getBoundingClientRect()
    this.nodes[0].pos.set(
      bnds1.x + bnds1.width * .5,
      bnds1.y + bnds1.height * .5,
    )
    
    // the other one
    if (!this.broken) {
      const bnds2 = this.movingAnchor.getBoundingClientRect()
      this.nodes[this.nodes.length-1].pos.set(
        bnds2.x + bnds2.width * .5,
        bnds2.y + bnds2.height * .5,
      )
    }
  }

  updateNodes = () => {
    // first update mouse interactions
    if (this.transitionOut === false) {
      for (const node of this.nodes) {
        node.updateMouse(this.mouseX, this.mouseY, this.mouseRad)
      }
    }

    // then compute the repRange and repForce based on time
    const t = this.elapsed * 0.001
    const repRangeSq = (this.alive && this.transitionOut === false) ? this.repRangeSq + fVar(t*2) * 1000 : this.repRangeSq
    const repForce = (this.alive && this.transitionOut === false) ? this.repForce + fVar(t*2) * 5000 : this.repForce
    const linkForce = this.alive ? this.linkForce*4 : this.linkForce

    // update the nodes
    for (const node of this.nodes) {
      if (this.broken) {
        node.updateRepulsion(this.nodes, this.repForce, repRangeSq)
      }
      node.updateLinksForces(linkForce, this.broken)
    }

    // get pull strength from last node attraction to the chain
    this.pullStrength = this.nodes[this.nodes.length-1].acc.y

    // run the update of the nodes
    for (const node of this.nodes) {
      node.update(this.dt * 0.001, this.broken)
    }
  }

  renderNodes = () => {    
    if (!this.broken) {
      this.ctx.strokeStyle = "rgba(255, 0, 0, 0.8)"
      this.ctx.lineWidth = 2
      for (const node of this.nodes) {
        for (const edge of node.edges) {
          this.ctx.beginPath()
          this.ctx.moveTo(edge.pos.x, edge.pos.y)
          this.ctx.lineTo(node.pos.x, node.pos.y)
          this.ctx.stroke()
        }
      }
    }
    else {
      this.ctx.fillStyle = "white"
      this.ctx.beginPath()
      this.ctx.moveTo(this.nodes[0].pos.x, this.nodes[0].pos.y)
      for (let i = 1; i < this.nodes.length; i++) {
        this.ctx.lineTo(this.nodes[i].pos.x, this.nodes[i].pos.y)
      }
      this.ctx.fill()      
    }

    // this.ctx.fillStyle = "rgba(255, 255, 255, 0.2)"
    // this.ctx.strokeStyle = "rgba(255, 0, 0, 0.9)"
    // for (let i = 0; i < this.nodes.length; i++) {
    //   const node = this.nodes[i]
    //   this.ctx.fillStyle = `rgba(255, 0, 0, ${i/this.nodes.length})`
    //   this.ctx.beginPath()
    //   this.ctx.arc(node.pos.x, node.pos.y, 10, 0, 2*Math.PI)
    //   this.ctx.fill()
    //   this.ctx.stroke()
    // }
  }

  renderTransitionOut = () => {
    let t = (this.elapsed - this.transitionOut) / transitionOutDuration
    if (t > 1) {
      if (!this.transitionOutEnded) {
        this.transitionOutEnded = true
        this.onTransitionOutEnd && this.onTransitionOutEnd()
      }
      return
    }

    if (t+(this.dt*0.001) > 1) {
      t = 1
    }

    // we can update the nodes position based on the values computed previously
    t = Easings.quadInOut(t)
    let P = new Vector2()
    for (let i = 0; i < this.nodes.length; i++) {
      P.copy(this.initialPositions[i]).add(this.targetPositions[i].clone().sub(this.initialPositions[i]).multiplyScalar(t))
      this.nodes[i].pos.copy(P)
    }
  }

  renderMouse = () => {
    this.mouseElem.style.top = this.mouseY - this.mouseElem.clientWidth*.5 + 'px'
    this.mouseElem.style.left = this.mouseX - this.mouseElem.clientHeight*.5 + 'px'
  }

  breakChain = () => {
    const end = this.nodes[this.nodes.length-1]
    end.addEdge(this.nodes[0])
    this.nodes[0].edges.unshift(end)
    end.fixed = false
    this.broken = true
    this.onBreaking && this.onBreaking()
  }

  scrollUpdate = () => {
    if (!this.broken) {
      const scroll = window.scrollY

      if (scroll >= document.documentElement.scrollHeight - window.innerHeight) {
        this.breakChain()
        window.scrollTo({
          top: scroll + 100,
          behavior: 'smooth',
        })
        return
      }
      window.scrollTo(0, scroll - this.pullStrength/120)
    }
  }

  growth = () => {
    const t = this.elapsed - this.lastGrowth
    if (t >= growthDelay) {
      this.lastGrowth = this.elapsed
      this.addPoint(0, Math.max(1, (Math.random()*(this.nodes.length-1))|0))
    }
  }

  loop () {
    if (this.isRunning) {
      requestAnimationFrame(this.loop)
      super.loop()  // compute the timers
      
      // for measuring loop exec time
      const started = performance.now()

      // clear then render
      this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height)

      // update stuff
      if (this.transitionOut === false) {
        if (this.broken && !this.alive) {
          this.growth()
        }
        this.updateAnchors()
        this.updateNodes()
        if (this.elapsed > 200) {
          this.scrollUpdate()
        }
      }
      else {
        this.renderTransitionOut()
      }

      // draw stuff
      this.renderNodes()
      this.renderMouse()

      // time of execution of the loop
      const execTime = performance.now() - started
      this.execTimeHist.shift()
      this.execTimeHist.push(execTime)
      const execAvg = this.execTimeHist.reduce((a,b) => a+b) / this.execTimeHist.length
      // console.log({ loopExec: execAvg, nodes: this.nodes.length })

      // if the exec time become too high, we stop growth and enter phase 3
      if (execAvg > 11) {
        this.alive = true
      }
    }
  }

  destroy () {
    return new Promise(resolve => {
      document.removeEventListener("mousemove", this.onMouseMoveDocument)
      document.removeEventListener("mousedown", this.onMouseDownDocument)
      window.removeEventListener("resize", this.onWindowResize)

      resolve()
    })
  }
}

PerturbationsExperiment.link = "/perturbations"
PerturbationsExperiment.title = "Perturbations of the natural inputs"
PerturbationsExperiment.description = "How can carefully crafted perturbations change the whole user experience ?"
PerturbationsExperiment.component = PerturbationsExperimentComponent

export default PerturbationsExperiment