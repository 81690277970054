import React from "react"
import styled from "styled-components"



const Story = () => {
  return (
    <>
      <p>
        This experiment is probably not a good example to describe this particular point. It’s more focused on technical explorations rather than the elaboration of an interesting story. But it’s easy to think about how these concepts can be used to help construct a red wire through the evolution of a user within a document.
      </p>

      <p>
        Knowing how a user interacts naturally with a document will help in getting their attention when required. Sometimes, even, a <strong>perturbation</strong> of these natural processes will be enough to achieve such an effect. This is what the next experiment explores.
      </p>
    </>
  )
}

export default Story