import React from "react"
import styled from "styled-components"



const Classification = () => {
  return (
    <>
      <p>
        The previous experiment, <em>“The artistic expression of a document”</em>, showed how the scroll could be used in a very particular way of communication with the visuals through the DOM. However, there are more <strong>natural</strong> ways to interact with a document. I found that the natural user inputs could be classified into 4 categories depending on the effect they have over the DOM:
      </p>

      <ul>
        <li>
          <strong>viewport transformations</strong>: scrolling / resizing (translating / scaling) the window. This type of interaction transforms the position and/or size of the elements relative to the viewport. The former is the most used, but I think the latter also fits in the same category given the nature of its effects.
        </li>
        <li>
          <strong>content transformations</strong>: any action from which some parts or the whole document changes. Navigating to a different page fits in this category, but less destructive transformations would as well. For instance, expanding a container will only change a minor section of the document, but it fits in the same type of transformation. It should be noted that even though most of these actions will be triggered by an action applied to the mouse, the mouse itself is only a tool achieving the transformation of the content. One could use the keyboard (TAB to navigate, ENTER to click), his fingers on touch devices, his voice, a PS4 controller… 
        </li>
        <li>
          <strong>unidirectionnal data transmission</strong>: any input which has a visible feedback on the document but does not update the areas of the elements. For instance, filling fields in a form.
        </li>
        <li>
          <strong>information emphasis</strong>: an action whose initial intent is to either gather information about an element or help in the understanding of the document. Maybe examples will make it clearer: hovering some text to see if it’s a link or to see where the link points, overlining some text of particular interest to keep track of it, moving the cursor next to a section being read...
        </li>
      </ul>

      <p>
        It should be noted that any input can be classified into multiple categories, depending on how the application design handles them. 
      </p>
      <p>
        A formal example would be an inscription form that displays errors as the user enters text in a field. The text updated within the field is classified as “<strong>unidirectionnal data transmission</strong>”, as long as it doesn’t change the size of the input. However, if an error is displayed at the top of the input, a new element needs to be created and displayed, thus resulting in a “<strong>content transformation</strong>”: the input is thus classified into both categories.
      </p>

      <p>
      For you to get a better feeling of these concepts, the next chapter will cover which user inputs this third experiment makes usage of.
      </p>
    </>
  )
}

export default Classification