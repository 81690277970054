import React, { useRef} from "react"
import styled from "styled-components"

import { Link } from "react-router-dom"


const StCont = styled.div`
  line-height: 32px;

  .index, h4 {
    font-size: 20px;
    font-variation-settings: 'wght' 400;
    transition: .3s;
  }

  .index {
    position: relative;
    
    &::after {
      content: "";
      display: ${props => props.hideHover ? 'none' : 'block'};
      position: absolute;
      top: 60%;
      right: -15px;
      transform: translate(0, -50%);
      border: 1px solid white;
      border-radius: 50%;
      opacity: 0;
      transition: all .6s ease-out;
      width: 200vh;
      height: 200vh;
      pointer-events: none;
    }
  }

  .description {
    font-size: 15px;
    font-weight: 300;
    opacity: 0.7;
    position: relative;
    left: 0;
    letter-spacing: 4px;
    transition: .3s;
  }

  &:hover {
    .index, h4 {
      font-size: 28px;
      font-variation-settings: 'wght' 700;
    }
    .description {
      left: 20px;
      letter-spacing: 0;
    }
    .index::after {
      opacity: 0.3;
      width: 50px;
      height: 50px;
    }
  }
`

const NavItem = ({ index, title, description, link, onClick, className, onMouseEnter, onMouseLeave, hideHover }) => {
  // must keep item reference because onMouseEnter sends children as targets
  const containerRef = useRef()

  const onLinkClicked = (event) => {
    event.preventDefault()
    onClick()
  }

  return (
    <Link 
      to={link} 
      onClick={onLinkClicked}
      onMouseEnter={(evt) => onMouseEnter(evt, containerRef.current)}
      onMouseLeave={(evt) => onMouseLeave(evt, containerRef.current)}
      ref={containerRef}
    >
      <StCont className={`${className} line-item`}>
        {index ? (
          <div className="index">{ index }.</div>
        ):(
          <div/>
        )}
        <div className="infos">
          <h4>{ title }</h4>
          <span className="description">{ description }</span>
        </div>
      </StCont>
    </Link>
  )
}

export default NavItem