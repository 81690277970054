import React from "react"
import styled from "styled-components"


const NotedText = () => {
  return (
    <>
      <h4>6 - ADVERTISING</h4> 

      <p>
        "<span data-note="0.85">Life</span> is <span data-note="1">beautiful</span>. Do not listen to <span data-note="0.05">gossip</span>. <span data-note="0.8">Life</span> is <span data-note="0.9">good</span>. <span data-note="0.7">Life</span> is a product <span data-note="0.5">tested</span> and <span data-note="0.82">approved</span> by more than seventy billion <span data-note="0.5">humans</span> for three million years. This proves its <span data-note="0.5">quality</span> irreplaceable. " <br/>
        This is a <span data-note="0.6">message</span> of ANPV, the National Agency for the promotion of life. 
      </p>

      <h4>7 - MANUAL <span data-note="0.5">HISTORY</span></h4> 

      <p>
        Until the appearance of thanatonautique, <span data-note="0.01">death</span> was considered one of the main <span data-note="0.25">taboos</span> of humanity. To better <span data-note="0.45">fight</span> against his own image, men were using mental processes we would call <span data-note="0.45">superstition</span>. Some considered, for example, a metal coin with the image of St. Christopher, attached to a dashboard, <span data-note="0.91">helped</span> avoid <span data-note="0.05">fatal</span> car <span data-note="0.08">accidents</span>. <br/> 
        Before the third century, and commonly <span data-note="0.75">joked</span>: "In case of car accident is the driver who has the biggest Christophe saint who is most likely to get out." 
      </p>

      <p>
        <span data-note="0.5">History</span> textbook, Basic Course 2 year. 
      </p>

      <h4>8 - LESS WHERE THE HERO <span data-note="0.08">DIES</span> WE COULD BELIEVE</h4> 

      <p>
        Hold. <span data-note="0.5">Nothing</span> terrible happened. <br/>
        Great-great-grandfather was wrong. <span data-note="0.2">Dying</span> was not so <span data-note="0.08">awful</span> as that. There is nothing going on and that was it. <br/>
        The black and the <span data-note="0.6">silence</span> lasted a long time. <br/>
        Finally, I opened my eyes. A slender figure appeared in an opaque <span data-note="0.72">halo</span> of <span data-note="0.85">light</span>. <br/>
        An <span data-note="0.92">angel</span>, surely. <br/>
        The angel leaned on me. The <span data-note="0.9">angel</span> looked strangely like a woman, but a <span data-note="0.92">beautiful</span> woman like you never see on earth. She was blonde, with brown eyes. <br/>
        Her <span data-note="0.74">perfume</span> smelled apricot. <br/>
        Around us, everything was white and <span data-note="0.65">serene</span>. <br/>
        I had to be in <span data-note="0.95">Heaven</span> because the angel smiled. <br/>
        - Ouahé udéen ... ... ... éatu you. <br/>
        <span data-note="0.75">Angels</span> had to speak a language to them. Jargon <span data-note="0.24">incomprehensible</span> to non-angel angels. <br/>
        - Fou ... ... nafhé ludéhen ... éatuheu. <br/>
        She repeated this chant with <span data-note="0.74">patience</span> and passed me his <span data-note="0.68">soft</span>, cool hand on my forehead smooth <span data-note="0.14">injured</span> child. <br/>
        - You ... have ... more ... temperature. <br/>
        I looked around, somewhat <span data-note="0.35">dazed</span>. <br/>
        - Are you okay? You understand me? You have no temperature. <br/>
        - Where am I? In <span data-note="0.86">Paradise</span>? <br/>
        - No. In the intensive care unit at St. Louis <span data-note="0.84">hospital</span>. <br/>
        The <span data-note="0.79">angel</span> <span data-note="0.82">reassured</span> me. <br/>
        - You're not dead. You have just a few <span data-note="0.4">bruises</span>. You're <span data-note="0.92">lucky</span> that the hood of the car has cushioned your fall. You have a big gash in his knees.
      </p>
    </>
  )
}

export default NotedText