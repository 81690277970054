/**
 * A generic-purpose class made to compute the bounds of visible elements within a container.
 */
class DomToEnv {
  /** @type {Array.<HTMLElement>|string} */
  elements = []

  /** @type {HTMLElement} */
  container = null

  /**
   * 
   * @param {Array.<HTMLElement>|string} elements can either be a list of HTMLElement or a string CSS selector
   * @param {HTMLElement?} container the container in which visible blocks should be computed. If null, window will be used
   */
  constructor (elements, container = null) {
    this.container = container
    this.elements = elements
  }

  /**
   * 
   * @param {Array.<HTMLElement>|string} elements can either be a list of HTMLElement or a string CSS selector
   */
  updateElements (elements) {
    if (typeof elements === "string") {
      elements = document.querySelectorAll(elements)
    }
    this.elements = elements
  }

  /**
   * @returns {DOMRect} a rectangle bounds of the container, relative to the window. If container is null, will return the 
   *                    viewport bounds 
   */
  getContainerBounds () {
    if (this.container) {
      return this.container.getBoundingClientRect()
    }
    return new DOMRect(0, 0, window.innerWidth, window.innerHeight)
  }

  /**
   * @returns {Array.<DOMRect>} an array of DOM Rectangles, relative to the container (if no container, to the viewport)
   */
  getVisibleRects () {
    const cBounds = this.getContainerBounds()
    const inside = []
    let bounding

    for (const elem of this.elements) {
      bounding = elem.getBoundingClientRect()
      // most optimized way I found to know if element is in the container bounds
      if (bounding.top + bounding.height > cBounds.y && bounding.top < cBounds.y+cBounds.height) {
        // now we convert the position into normalized coordinates
        bounding.x-= cBounds.x
        bounding.y-= cBounds.y
        inside.push(bounding)
      }
    }
  
    return inside
  }
}

export default DomToEnv