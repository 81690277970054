import {
  NearestFilter,
  RGBAFormat,
  FloatType,
  WebGLRenderTarget,
  Scene,
  OrthographicCamera,
  PlaneBufferGeometry,
  Mesh,
  DataTexture,
  Vector2,
} from "three"

export default class QuadTarget {
  constructor (width, height, material) {
    this.width = width;
    this.height = height;
    let w = width;
    let h = height;
    let options = {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: FloatType
    }

    let rt = new WebGLRenderTarget(this.width, this.height, options);
    this.rt = rt;

    this.material = material;
    this.material.uniforms["resolution"] = {value : new Vector2(w,h)};
    this.material.uniforms["time"] = {value : 0};
    this.material.transparent = true;

    this.mesh = new Mesh(new PlaneBufferGeometry(), this.material);

    this.scene = new Scene();
    this.camera = new OrthographicCamera(-w / 2, w / 2, h / 2, -h / 2, 0.1, 100);
    this.camera.position.z = 1;
    this.scene.add(this.mesh);
  }
  
  /**
   * 
   * @param {WebGLR} renderer 
   * @param {*} time 
   */
  render (renderer, time=0) {
    this.mesh.visible = true;
    
    this.material.uniforms.time.value = time;

    const olSize = new Vector2() 
    renderer.getSize(olSize)

    renderer.setSize(this.width, this.height);
    renderer.setRenderTarget(this.rt);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null);
    renderer.setSize(olSize.x, olSize.y)
    this.mesh.visible = false;
  }

  get texture () {
    return this.rt.texture;
  }

}